<template>
  <div class="d-flex align-items-center justify-content-center mt-3">
    <button
      class="btn shadow-none p-0 me-2 mb-0"
      :disabled="loading"
      @click="handleNavigation('prev')"
    >
      <i class="fas fa-chevron-left"></i>
    </button>
    <div class="text-center">{{ selectedDateInfo }}</div>
    <button
      class="btn shadow-none p-0 ms-2 mb-0"
      :disabled="loading || isCurrentPeriod"
      @click="handleNavigation('next')"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
import { dateFormat } from "@/lib/dateHelper";

export default {
  name: "HealthNavigator",
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    today: {
      type: String,
      required: true,
    },
    interval: {
      type: [String, Number],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedDateInfo() {
      return this.startDate === this.endDate
        ? dateFormat(this.startDate, "Do MMMM")
        : `${dateFormat(this.startDate, "Do MMMM")} - ${dateFormat(
            this.endDate,
            "Do MMMM"
          )}`;
    },
    isCurrentPeriod() {
      return this.endDate === this.today;
    },
  },
  methods: {
    handleNavigation(direction) {
      if (this.loading) return;

      let newStartDate, newEndDate;

      if (this.interval === "day") {
        const date =
          direction === "prev"
            ? new Date(new Date(this.startDate).getTime() - 86400000)
            : new Date(new Date(this.startDate).getTime() + 86400000);
        newStartDate = dateFormat(date, "YYYY-MM-DD");
        newEndDate = newStartDate;
      } else if (typeof this.interval === "number") {
        const days = Math.abs(this.interval);

        if (direction === "prev") {
          newEndDate = dateFormat(
            new Date(new Date(this.startDate).getTime() - 86400000),
            "YYYY-MM-DD"
          );
          newStartDate = dateFormat(
            new Date(new Date(newEndDate).getTime() - (days - 1) * 86400000),
            "YYYY-MM-DD"
          );
        } else {
          newStartDate = dateFormat(
            new Date(new Date(this.endDate).getTime() + 86400000),
            "YYYY-MM-DD"
          );
          newEndDate = dateFormat(
            new Date(new Date(newStartDate).getTime() + (days - 1) * 86400000),
            "YYYY-MM-DD"
          );
        }
      }

      // Prevent navigation beyond current date
      if (direction === "next" && new Date(newEndDate) > new Date(this.today)) {
        return;
      }

      this.$emit("update-graph", newStartDate, newEndDate, this.interval);
    },
  },
};
</script>
