<template>
  <div
    class="mx-5 mb-4 rounded p-2 bg-white shadow mb-2 d-flex justify-content-between align-items-center"
  >
    <div>{{ title }}</div>
    <div><slot /></div>
  </div>
</template>
<script>
export default {
  name: "ResultDisplay",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
