import { httpApi } from "@/services/httpApi";
import { handleError } from "@/lib/helpers";
import { showMessage } from "@/assets/js/show-message";

class ApiAddresses {
  getAddressText(addressObj) {
    return (
      addressObj.type +
      ": " +
      addressObj.flat_number +
      ", " +
      addressObj.building_name +
      ", " +
      addressObj.landmark +
      ", " +
      addressObj.street +
      ", " +
      addressObj["city.name"] +
      ", " +
      addressObj["area.name"]
    );
  }

  async get(client_id, status = null) {
    let url = `/api/v1/clients/${client_id}/addresses`;
    if (status) {
      url += `/?status=${status}`;
    }
    return await httpApi.get(url);
  }

  async update(client_id, address_id, data) {
    return await httpApi.post(
      "/api/v1/clients/" + client_id + "/addresses/" + address_id,
      data
    );
  }

  async create(client_id, data) {
    return await httpApi.post(
      "/api/v1/clients/" + client_id + "/addresses",
      data
    );
  }

  async delete(client_id, address_id) {
    return await httpApi.delete(
      "/api/v1/clients/" + client_id + "/addresses/" + address_id
    );
  }

  addressTypeChoices(empty_option_name) {
    let options = ["Home", "Office", "Other"]; //https://gitlab.com/kcal2/ladybird-2/-/blob/main/app/Enums/AddressType.php
    let result = [];
    if (empty_option_name) {
      result.push({
        value: "",
        label: empty_option_name,
        id: "",
      });
    }
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }
  async getAreas(params) {
    const response = await httpApi
      .get("api/v1/areas?" + params)
      .catch(handleError);
    if (response.status === 200) {
      return response.data.data;
    } else {
      showMessage(response.message, "error");
      return [];
    }
  }

  async getCities(params) {
    const response = await httpApi
      .get("api/v1/cities?" + params)
      .catch(handleError);
    if (response.status === 200) {
      return response.data.data;
    } else {
      showMessage(response.message, "error");
      return [];
    }
  }
  async getAreaByCoordinates(data) {
    return await httpApi.post("/api/v1/addresses/get-area", data);
  }
}

export default new ApiAddresses();
