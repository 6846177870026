<template>
  <div class="mb-4 card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex flex-row justify-content-between">
        <h6 class="mb-1">Wallet Transactions</h6>
        <wallet-withdraw-modal :loading="loading" @update="onSubmit" />
      </div>
    </div>
    <div class="p-3 card-body">
      <table class="table align-items-center mb-0 hover">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Ref No.
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Date
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Type
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(transaction, index) of transactions" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">
                    {{ transaction.uuid }}
                  </h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">
                {{ transaction.created_at }}
              </p>
            </td>
            <td>
              <div
                :class="`${
                  transaction.type == `deposit`
                    ? `btn-outline-success`
                    : `btn-outline-danger`
                }  align-items-center btn btn-icon-only btn-rounded btn-sm d-flex justify-content-center mb-0 p-3`"
              >
                <i
                  :class="`fas ${
                    transaction.type == `deposit`
                      ? `fa-arrow-up`
                      : `fa-arrow-down`
                  }`"
                ></i>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">
                {{ currencyFormat(transaction.amount) }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { currencyFormat, handleError, handleResponse } from "@/lib/helpers";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import WalletWithdrawModal from "./WalletWithdrawModal";

export default {
  name: "WalletTransactions",
  components: {
    WalletWithdrawModal,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    transactions: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["updateList"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    currencyFormat,
    async onSubmit(data) {
      if (!data.amount || !data.description || Number(data.amount) === 0) {
        return;
      }
      this.loading = true;

      let formData = new FormData();
      formData.append("amount", data.amount);
      formData.append("description", data.description);

      let response = await API.withdrawWallet(
        this.$route.params.id,
        formData
      ).catch(handleError);

      await handleResponse(response, async () => {
        let planModal = this.$store.state.bootstrap.Modal.getInstance(
          document.getElementById("withdrawModal")
        );
        planModal.hide();
        this.$emit("updateList");
        showMessage("Withdrawal Success");
      });

      this.loading = false;
    },
  },
};
</script>
