<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <h4 class="text-white">Make the changes below</h4>
      </div>
      <div
        class="text-right col-lg-6 d-flex flex-column justify-content-center"
      >
        <button
          type="button"
          class="mt-2 mb-0 btn btn-outline-white ms-lg-auto me-lg-0 me-auto mt-lg-0"
          @click="saveIngredient"
        >
          Save
        </button>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Ingredient Image</h5>
            <div class="row">
              <div class="col-12">
                <img
                  class="mt-3 shadow-lg w-100 border-radius-lg"
                  :src="ingredient.image"
                  alt="ingredient image"
                  @click="showImagePicker"
                />

                <input
                  ref="file"
                  type="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="selectImage"
                />
              </div>
              <div class="mt-5 col-12">
                <div class="d-flex">
                  <button
                    class="mb-0 btn bg-gradient-success btn-sm me-2"
                    type="button"
                    name="button"
                    @click="showImagePicker"
                  >
                    Upload
                  </button>
                  <button
                    v-if="ingredient.image != ingredientDefaultImage"
                    class="mb-0 btn btn-outline-dark btn-sm"
                    type="button"
                    name="button"
                    @click="removeImage"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Ingredient Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="ingredient-name"
                  v-model="ingredient.name"
                  class="form-control"
                  type="text"
                  placeholder="Ingredient name"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Type</label>
                <select
                  id="type"
                  v-model="ingredient.type"
                  class="form-control"
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Parent Ingredient</label>
                <select
                  id="parent-ingredient"
                  v-model="ingredient.parent_id"
                  class="form-control"
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Allergens</label>
                <select
                  id="ingredient-allergen"
                  v-model="ingredient.allergen_ids"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Status</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Active </label>
                  <argon-switch
                    id="ingredient-status-switch"
                    v-model:checked="ingredient.status"
                    name="ingredient_status"
                  />
                </div>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Is public</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Yes </label>
                  <argon-switch
                    id="ingredient-is-public-switch"
                    v-model:checked="ingredient.is_public"
                    name="ingredient_is_public"
                  />
                </div>
              </div>
              <div class="mt-3 col-12 mt-sm-0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ingredientDefaultImage from "@/assets/img/ingredient.png";
import API from "@/services/api";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message.js";
import { initChoices } from "@/assets/js/init-choices";
import ApiAllergens from "@/services/apiAllergens";
import apiIngredients from "@/services/apiIngredients";

export default {
  name: "New",
  components: {
    ArgonSwitch,
  },
  data() {
    return {
      ingredientDefaultImage,
      ingredientImage: undefined,
      ingredient: {
        name: "",
        type: "",
        parent_id: "",
        parent_name: "",
        allergen_ids: "",
        allergen_name: "",
        image: "",
      },
    };
  },
  async mounted() {
    this.ingredient.image = ingredientDefaultImage;
    await initChoices(
      "type",
      { choices: API.getIngredientTypeOptions() },
      this.ingredient.type
    );
    await this.initParentIngredientChoices();
    await this.initIngredientAllergenChoices();
  },
  methods: {
    async initParentIngredientChoices() {
      await apiIngredients
        .get("start=0&length=-1")
        .then((res) => {
          initChoices(
            "parent-ingredient",
            { choices: API.formatDataToChoicesJs(res.data.data) },
            this.ingredient.parent_id
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initIngredientAllergenChoices() {
      await ApiAllergens.get("start=0&length=-1")
        .then((res) => {
          initChoices(
            "ingredient-allergen",
            { choices: API.formatDataToChoicesJs(res.data.data) },
            this.ingredient.allergen_ids
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },

    async saveIngredient() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      formData.append("name", this.ingredient.name);
      formData.append("type", this.ingredient.type);
      if (this.ingredient.parent_id > 0) {
        formData.append("parent_id", this.ingredient.parent_id);
      }
      for (let key in this.ingredient.allergen_ids) {
        formData.append("allergen_ids[]", this.ingredient.allergen_ids[key]);
      }
      formData.append(
        "is_public",
        this.ingredient.is_public === true ? "1" : "0"
      );
      formData.append(
        "status",
        this.ingredient.status === true ? "Active" : "Inactive"
      );
      if (this.ingredientImage) {
        formData.append("image", this.ingredientImage);
      }
      await API.createIngredient(formData)
        .then((response) => {
          showMessage(
            "Success. Ingredient created.",
            { name: "fa-check-circle", class: "text-success" },
            1500,
            () => {
              this.$router.push({
                name: "Edit Ingredient",
                params: { id: response.data.data.id },
              });
            }
          );
        })
        .catch((err) => {
          let message = err.message;
          if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
          }
          showMessage(
            message,
            { name: "fa-exclamation-triangle", class: "text-danger" },
            3500
          );
        });
      this.formSubmitted = false;
    },

    showImagePicker() {
      this.$refs.file.click();
    },
    selectImage() {
      this.ingredientImage = this.$refs.file.files.item(0);
      this.ingredient.image = URL.createObjectURL(this.ingredientImage);
    },
    removeImage() {
      this.ingredientImage = undefined;
      this.ingredient.image = this.ingredientDefaultImage;
    },
  },
};
</script>
