<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div
          class="text-right col-12 d-flex flex-column justify-content-center"
        >
          <div class="ms-lg-auto me-lg-0 me-auto mt-lg-0">
            <router-link
              :to="{ name: 'Clients List' }"
              class="btn btn-white text-success mb-0"
            >
              Back
            </router-link>
          </div>
        </div>
      </div>

      <loading-spinner :loading="loading.client" />

      <div class="row d-none">
        <div class="col-lg-12">
          <div class="row user-stats">
            <div class="col-lg-3 col-md-6 col-12">
              <mini-statistics-card
                title="Wallet"
                :value="currencyFormat(total_balance)"
                :description="balance"
                :icon="{
                  component: 'fa fa-usd',
                  background: 'bg-gradient-dark',
                  shape: 'rounded-circle',
                }"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow-lg mt-4">
        <div class="card shadow-lg">
          <div class="card-body p-3">
            <div class="row gx-4">
              <div class="col-auto my-auto">
                <div class="avatar avatar-xl position-relative">
                  <img
                    :src="client.profile_picture"
                    alt="profile_image"
                    class="shadow-sm w-100 border-radius-lg"
                  />
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1">{{ clientName }}</h5>
                </div>
              </div>
              <div
                class="mx-auto mt-3 col col-xl-6 my-sm-auto ms-sm-auto me-sm-0"
              >
                <div class="nav-wrapper position-relative end-0">
                  <ul
                    class="p-1 bg-transparent nav nav-pills nav-fill"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        ref="profile_tab"
                        class="px-0 py-1 mb-0 nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-content-profile"
                        role="tab"
                        aria-selected="true"
                      >
                        <icon-cube size="16" />
                        <span class="ms-1">Profile</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="px-0 py-1 mb-0 nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-content-remarks"
                        role="tab"
                        aria-selected="false"
                        @click="setClientRemarks(client.id)"
                      >
                        <icon-single-copy size="16" />
                        <span class="ms-1">Remarks</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="px-0 py-1 mb-0 nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-content-invoices"
                        role="tab"
                        aria-selected="false"
                        @click="setClientInvoices"
                      >
                        <i class="fa fa-file-invoice"></i>
                        <span class="ms-1">Invoices</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="px-0 py-1 mb-0 nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-content-health"
                        role="tab"
                        aria-selected="false"
                        @click="fetchHealthData = true"
                      >
                        <i class="fa fa-file-invoice"></i>
                        <span class="ms-1">Health</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="px-0 py-1 mb-0 nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-content-consultations"
                        role="tab"
                        aria-selected="false"
                        @click="setClientConsultations"
                      >
                        <i class="fa fa-file-invoice"></i>
                        <span class="ms-1">Consultations</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="px-0 py-1 mb-0 nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-content-wallet"
                        role="tab"
                        aria-selected="false"
                        @click="setWalletTransactions"
                      >
                        <i class="fa fa-wallet"></i>
                        <span class="ms-1">Wallet</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="px-0 py-1 mb-0 nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-content-activity"
                        role="tab"
                        aria-selected="false"
                        @click="showClientActivity = true"
                      >
                        <i class="fa fa-history"></i>
                        <span class="ms-1">Activity</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div
          id="tab-content-profile"
          class="tab-pane fade active show"
          role="tabpanel"
        >
          <div class="card shadow-lg mt-4">
            <div class="card shadow-lg">
              <div class="card-body p-3">
                <router-link
                  :to="{
                    name: 'Client Bag Movements',
                    params: {
                      id: client.id,
                    },
                  }"
                  class="btn mb-0 btn-outline-success btn-sm"
                  >Bag details
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <profile-info-card
                class="h-100"
                title="Profile Information"
                :info="{
                  bio: client.notes,
                  fullName: clientName,
                  mobile: client.mobile_number,
                  email: client.email,
                  height: client.height,
                  weight: client.weight,
                  goal: client.goal,
                  accountManager: client['account_manager.name'],
                  nutritionist: client['nutritionist.name'],
                  salesOwner: client['sales_owner.name'],
                  location: 'UAE',
                }"
                :action="{
                  route: $router.resolve({
                    name: 'Edit Client',
                    params: {
                      id: client.id,
                    },
                  }).href,
                  tooltip: 'Edit',
                }"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <profile-addresses-card
                class="h-100"
                :addresses="client.addresses"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Dislike Exclusions"
                :items="client.dislikeExclusions"
                dislike-type=""
                :action="{
                  target: '#dislike-exclusion-modal',
                  name: 'Add',
                }"
                :client-id="client.id"
                color="success"
                @delete="deleteDislikeExclusion"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Allergens"
                :items="client.dislikes"
                dislike-type="allergen"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                :client-id="client.id"
                color="danger"
                @delete="deleteDislike"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Ingredient Allergens"
                :items="client.dislikes"
                dislike-type="ingredient_allergen"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                :client-id="client.id"
                color="danger"
                @delete="deleteDislike"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Ingredient Dislikes"
                :items="client.dislikes"
                dislike-type="ingredient"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                :client-id="client.id"
                color="danger"
                @delete="deleteDislike"
              />
            </div>

            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Food Group Dislikes"
                :items="client.dislikes"
                dislike-type="food_group"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                :client-id="client.id"
                color="danger"
                @delete="deleteDislike"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Recipe Dislikes"
                :items="client.dislikes"
                dislike-type="recipe"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                :client-id="client.id"
                color="danger"
                @delete="deleteDislike"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-4">
              <plans-card
                :plans="client.plans"
                :client-id="client.id"
                :show-new-plan-button="showNewPlanButton"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-remarks" class="tab-pane fade" role="tabpanel">
          <remarks-card
            :client-remarks="client.remarks"
            :client-id="client.id"
            @remark-created="setClientRemarks(client.id)"
          />
        </div>
        <div id="tab-content-invoices" class="tab-pane fade" role="tabpanel">
          <div class="mt-4 row">
            <div class="col-12">
              <invoices-card :invoices="client.invoices" />
            </div>
          </div>
        </div>
        <div id="tab-content-health" class="tab-pane fade" role="tabpanel">
          <div class="mt-4 row">
            <div class="col-12">
              <Health-Cards v-if="fetchHealthData" />
            </div>
          </div>
        </div>
        <div
          id="tab-content-consultations"
          class="tab-pane fade"
          role="tabpanel"
        >
          <div class="mt-4 row">
            <div class="col-12">
              <consultations-card
                :consultations="client.consultations"
                :client-id="client.id"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-wallet" class="tab-pane fade" role="tabpanel">
          <div class="mt-4 row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <wallet-transactions
                    :transactions="client.walletTransactions"
                    :client-id="client.id"
                    @update-list="setWalletTransactions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="tab-content-activity" class="tab-pane fade" role="tabpanel">
          <client-activity v-if="showClientActivity" :client-id="client.id" />
        </div>
      </div>
      <div
        id="plan-modal"
        class="modal fade"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="plan-modal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="plan.id > 0" class="modal-title">Update Plan</h5>
              <h5 v-else class="modal-title">New Plan</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <loading-spinner :loading="loading.planModal" />
            <div v-show="!loading.planModal" class="modal-body">
              <div class="row">
                <div class="col-12 col-md-6 mt-3">
                  <label>Plan</label>
                  <select
                    id="plan-choices"
                    v-model="plan.plan_id"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Type</label>
                  <select
                    id="plan-type-choices"
                    v-model="plan.plan_type"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 mt-3">
                  <label>Plan variant</label>
                  <select
                    id="plan-variant-choices"
                    v-model="plan.plan_variant_id"
                    class="form-control"
                  ></select>
                </div>

                <div class="col-12 col-md-6 mt-3">
                  <label>Weeks</label>
                  <select
                    id="plan-weeks-choices"
                    v-model="plan.number_of_weeks"
                    class="form-control"
                  ></select>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Start date</label>
                  <flat-pickr
                    v-model="plan.start_date"
                    class="form-control"
                    placeholder="Select date"
                    name="start_date"
                    :config="{
                      //dateFormat: 'M j, Y',
                      //minDate: startDateMin
                    }"
                  />
                </div>
                <div class="col-12 mt-3">
                  <label>Delivery days</label>
                  <div class="row">
                    <div
                      v-for="(el, index) in deliveryDays"
                      :key="index"
                      class="col-auto mt-2 mt-lg-0"
                    >
                      {{ el.day_text }}
                      <argon-switch
                        :id="'delivery-' + el.day"
                        v-model:checked="el.delivery"
                        :name="el.day_text"
                        class="form-check form-switch mb-0 d-flex align-items-center"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <argon-textarea
                    id="plan-notes"
                    placeholder=""
                    :model-value="plan.notes"
                    @update:model-value="plan.notes = $event"
                    >Notes
                  </argon-textarea>
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <argon-textarea
                    id="plan-private-notes"
                    placeholder=""
                    :model-value="plan.private_notes"
                    @update:model-value="plan.private_notes = $event"
                    >Private notes
                  </argon-textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                v-show="plan.status === 'Draft' && !loading.planModal"
                type="button"
                class="btn btn-primary"
                @click="savePlan"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <dislike-modal
        v-if="client.dislikes && dislikeMealTypeChoices.length > 0"
        :client-id="client.id"
        :client-dislikes="client.dislikes"
        :dislike-meal-type-choices="dislikeMealTypeChoices"
        @set-client-dislikes="setClientDislikes"
      />
      <dislike-exclusion-modal
        v-if="client.dislikeExclusions && dislikeMealTypeChoices.length > 0"
        :client-id="client.id"
        :client-dislike-exclusions="client.dislikeExclusions"
        :dislike-exclusion-meal-type-choices="dislikeMealTypeChoices"
        @set-client-dislike-exclusions="setClientDislikeExclusions"
      />
    </div>
  </main>
</template>

<script>
import constants from "@/constants";
import MiniStatisticsCard from "@/components/Cards/MiniStatisticsCard.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import profileImage from "@/assets/img/user-profile-icon.svg";
import API from "@/services/api";
import ApiClientDeliveryPreferences from "@/services/apiClientDeliveryPreferences";
import ApiMiscList from "@/services/apiMiscList";
import moment from "moment";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import {
  formatDataToChoicesJs,
  initChoices,
  setChoiceByValue,
} from "@/assets/js/init-choices";
import flatPickr from "vue-flatpickr-component";
import { showMessage } from "@/assets/js/show-message";
import LoadingSpinner from "@/components/LoadingSpinner";
import ProfileAddressesCard from "./components/ProfileAddressesCard.vue";
import IconCube from "@/components/Icons/Cube";
import IconSingleCopy from "@/components/Icons/SingleCopy";
import PlansCard from "./components/PlansCard";
import WalletTransactions from "./components/WalletTransactions";
import InvoicesCard from "./components/InvoicesCard";
import DislikesCard from "./components/DislikesCard";
import { handleError, currencyFormat } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";
import ConsultationsCard from "./components/ConsultationsCard";
import DislikeModal from "@/views/pages/Clients/components/DislikeModal.vue";
import DislikeExclusionModal from "@/views/pages/Clients/components/DislikeExclusionModal.vue";
import RemarksCard from "./components/RemarksCard.vue";
import { mapGetters } from "vuex";
import HealthCards from "@/views/pages/Health/index.vue";
import ClientActivity from "./components/ClientActivity.vue";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "Profile",
  components: {
    DislikeModal,
    HealthCards,
    DislikeExclusionModal,
    MiniStatisticsCard,
    DislikesCard,
    PlansCard,
    WalletTransactions,
    InvoicesCard,
    IconSingleCopy,
    IconCube,
    ProfileInfoCard,
    LoadingSpinner,
    flatPickr,
    ArgonTextarea,
    ArgonSwitch,
    ProfileAddressesCard,
    ConsultationsCard,
    RemarksCard,
    ClientActivity,
  },
  data() {
    return {
      showMenu: false,
      showClientActivity: false,
      profileImage,
      fetchHealthData: false,
      client: {
        id: this.$route.params.id,
        "account_manager.name": "",
        "nutritionist.name": "",
        "sales_owner.name": "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        gender: "",
        birth_date: "",
        bio: "",
        profile_picture: "",
        height: "",
        weight: "",
        goal_weight: "",
        dislikes: [],
        dislikeExclusions: [],
        plans: [],
        walletTransactions: [],
        invoices: [],
        addresses: [],
        wallets: [],
        consultations: [],
        remarks: [],
      },
      loading: {
        client: true,
        planModal: true,
        dislikeModal: true,
      },
      plansChoices: [],
      planVariants: [],
      planVariantsChoices: [],
      plan: {},
      dislike: {},
      dislikeExclusion: {},
      dislikeChoices: [],
      dislikeMealTypeChoices: [],
      dislikeExclusionChoices: [],
      formSubmitted: {},
      currentTab: "profile",
      total_balance: 0.0,
      balance: "",
      clientDeliveryPreferences: [],
      deliveryDays: this.defaultDeliveryDays(),
    };
  },
  computed: {
    clientName() {
      return this.client.first_name + " " + this.client.last_name;
    },
    ...mapGetters({
      hasRole: "auth/hasRole",
    }),
    showNewPlanButton() {
      if (!this.$ability.can("create", "client_plan_details")) {
        return false;
      }

      if (
        this.hasRole(constants.ROLES.SUPER_ADMIN) ||
        this.hasRole(constants.ROLES.SALES_MANAGER)
      ) {
        return true;
      }
      if (this.client.purchase_type === "New Sign Up") {
        return true;
      }
      if (
        (this.client.purchase_type === "Retention" ||
          this.client.purchase_type === "Bounce Back AM") &&
        (this.hasRole(constants.ROLES.ACCOUNT_MANAGER) ||
          this.hasRole(constants.ROLES.ACCOUNT_SUPERVISOR))
      ) {
        return true;
      }
      if (
        this.client.purchase_type == "Bounce Back Sales" &&
        this.hasRole(constants.ROLES.SALES_AGENT)
      ) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    setTooltip(this.$store.state.bootstrap);
    this.setDefaultPlan();
    await this.setClient();
    await this.setClientDislikes();
    await this.setClientDislikeExclusions();
    await this.setClientPlans();
    await this.setDislikeMealTypes();
    this.loading.client = false;
    await this.planModal();
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.layout = "default";
    body.classList.remove("profile-overview");
  },
  methods: {
    dateFormat,
    currencyFormat,
    setDefaultPlan() {
      this.plan = {
        plan_id: "",
        plan_variant_id: "",
        number_of_weeks: "",
        start_date: "",
        plan_type: "",
        notes: "",
        private_notes: "",
        status: "Draft",
        meta: null,
      };
    },

    setDefaultDislikeExclusion() {
      this.dislikeExclusion = {
        id: "",
        dislikable_id: "",
        dislikable_name: "",
        dislikable_type: "",
        meal_type: "",
      };
    },
    async planModal() {
      let appInstance = this;

      let planModalEl = document.getElementById("plan-modal");
      planModalEl.addEventListener("hide.bs.modal", async function () {
        appInstance.loading.planModal = true;
        setChoiceByValue("plan-weeks-choices", "");
        setChoiceByValue("plan-type-choices", "");
        setChoiceByValue("plan-choices", "");
        setChoiceByValue("plan-variant-choices", "");
      });
      planModalEl.addEventListener("shown.bs.modal", async function (event) {
        appInstance.loading.planModal = true;
        await appInstance.setClientDeliveryPreferences();
        await appInstance.setPlanChoices();
        await appInstance.initPlanChoices();
        await initChoices(
          "plan-weeks-choices",
          { choices: API.getValidWeeks(), shouldSort: false },
          appInstance.plan.number_of_weeks
        );
        await initChoices(
          "plan-type-choices",
          { choices: API.getClientPlanTypeChoices(), shouldSort: false },
          appInstance.plan.plan_type
        );
        let clientPlanId = 0;
        if (event.relatedTarget.dataset.planId) {
          clientPlanId = event.relatedTarget.dataset.planId;
          if (!appInstance.client.planDetails[clientPlanId]) {
            let response = await API.getClientPlanDetailsShow(
              appInstance.client.id,
              clientPlanId
            );
            appInstance.client.planDetails[clientPlanId] = response.data.data;
          }
          appInstance.plan.plan_id =
            appInstance.client.planDetails[clientPlanId]["plan.id"];
          appInstance.plan.plan_variant_id =
            appInstance.client.planDetails[clientPlanId]["planVariant.id"];
          appInstance.plan.number_of_weeks =
            appInstance.client.planDetails[clientPlanId].number_of_weeks;
          appInstance.plan.start_date =
            appInstance.client.planDetails[clientPlanId].start_date;
          appInstance.plan.plan_type =
            appInstance.client.planDetails[clientPlanId].plan_type;
          appInstance.plan.notes =
            appInstance.client.planDetails[clientPlanId].notes;
          appInstance.plan.private_notes =
            appInstance.client.planDetails[clientPlanId].private_notes;
          appInstance.plan.status =
            appInstance.client.planDetails[clientPlanId].status;
          appInstance.plan.meta =
            appInstance.client.planDetails[clientPlanId].meta === null
              ? {}
              : appInstance.client.planDetails[clientPlanId].meta;
        } else {
          appInstance.setDefaultPlan();
        }
        appInstance.plan.id = clientPlanId;
        setChoiceByValue("plan-type-choices", appInstance.plan.plan_type);
        setChoiceByValue("plan-choices", appInstance.plan.plan_id);
        setChoiceByValue(
          "plan-weeks-choices",
          appInstance.plan.number_of_weeks
        );
        await appInstance.setPlanVariantsChoices(appInstance.plan.plan_id);

        appInstance.deliveryDays = appInstance.getClientDeliveryDays();

        appInstance.loading.planModal = false;
      });
    },
    async setDislikeMealTypes() {
      let response = await ApiMiscList.substituteMenuMealTypes().catch(
        handleError
      );
      if (response.status === 200) {
        this.dislikeMealTypeChoices = formatDataToChoicesJs(
          response.data.data,
          "no empty option",
          { id: "value", value: "value", label: "value" }
        );
      }
    },

    async setClient() {
      const appInstance = this;
      await API.getClient(this.client.id, true)
        .then((res) => {
          this.client = {
            id: res.data.data.id,
            "account_manager.name": res.data.data["account_manager.name"],
            "nutritionist.name": res.data.data["nutritionist.name"],
            "sales_owner.name": res.data.data["sales_owner.name"],
            first_name: res.data.data.first_name,
            last_name: res.data.data.last_name,
            email: res.data.data.email,
            mobile_number: res.data.data.mobile_number,
            gender: res.data.data.gender,
            birth_date: res.data.data.birth_date,
            profile_picture: this.profileImage,
            notes: res.data.data.notes,
            height: res.data.data.profile.data.height,
            weight: res.data.data.profile.data.weight,
            goal: res.data.data.profile.data.goal,
            purchase_type: res.data.data.purchase_type,
            dislikes: [],
            dislikeExclusions: [],
            plans: [],
            invoices: [],
            planDetails: [],
          };
          if (res.data.data.profile_picture.length) {
            this.client.profile_picture = res.data.data.profile_picture;
          }
          this.client.addresses = res.data.data.addresses.data;
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Client not found",
              willClose: () => {
                appInstance.$router.push({ name: "Clients List" });
              },
            });
          } else {
            const response = handleError(err);
            showMessage(response.message, "error");
          }
        });
    },
    async setClientDislikes() {
      const appInstance = this;
      appInstance.client.dislikes = [];
      await API.getClientDislikes(this.client.id)
        .then((res) => {
          for (let i in res.data.data) {
            appInstance.client.dislikes.push({
              id: res.data.data[i].id,
              dislikable_name: res.data.data[i]["dislikable.name"],
              dislikable_id: res.data.data[i]["dislikable_id"],
              dislikable_type: res.data.data[i]["dislikable_type"],
              meal_type: res.data.data[i]["meal_type"],
            });
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setClientDislikeExclusions() {
      const appInstance = this;
      appInstance.client.dislikeExclusions = [];
      await API.getClientDislikeExclusions(this.client.id)
        .then((res) => {
          for (let i in res.data.data) {
            appInstance.client.dislikeExclusions.push({
              id: res.data.data[i].id,
              dislikable_name: res.data.data[i]["dislikable.name"],
              dislikable_id: res.data.data[i]["dislikable_id"],
              dislikable_type: res.data.data[i]["dislikable_type"],
              meal_type: res.data.data[i]["meal_type"],
            });
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setClientPlans() {
      await API.getClientPlanDetails(this.client.id)
        .then((res) => {
          this.client.plans = [];
          for (let i in res.data.data) {
            let start = moment(res.data.data[i]["start_date"]);
            let end = moment(res.data.data[i]["actual_end_date"]);
            this.client.plans.push({
              image: res.data.data[i]["image"],
              name: res.data.data[i]["planVariant.name"],
              id: res.data.data[i]["id"],
              start_date: start.format("D MMM YYYY"),
              actual_end_date: end.format("D MMM YYYY"),
              total_days: res.data.data[i]["total_days"],
              remaining_days: res.data.data[i].pending_days,
              status: res.data.data[i].status,
              sequence: res.data.data[i].sequence,
            });
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setWalletTransactions() {
      await API.getWalletTransactions(this.client.id)
        .then((res) => {
          this.client.walletTransactions = res.data.data;
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setWallets() {
      const appInstance = this;
      await API.getWallets(this.client.id)
        .then((res) => {
          appInstance.client.wallets = res.data.data;
          for (let i in this.client.wallets) {
            appInstance.total_balance += Number(
              appInstance.client.wallets[i].balance
            );
            appInstance.balance +=
              appInstance.client.wallets[i].slug +
              " : " +
              appInstance.client.wallets[i].balance +
              "<br /> ";
          }
          appInstance.total_balance = currencyFormat(appInstance.total_balance);
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setClientInvoices() {
      await API.getClientInvoices(this.client.id)
        .then((res) => {
          this.client.invoices = res.data.data;
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setPlanChoices() {
      await API.getPlans("start=0&length=-1")
        .then((res) => {
          this.plansChoices = API.formatDataToChoicesJs(res.data.data);
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initPlanChoices() {
      const id = "plan-choices";
      const element = document.getElementById(id);
      const appInstance = this;
      await initChoices(id, { choices: this.plansChoices });
      element.addEventListener(
        "addItem",
        async function (event) {
          await appInstance.setPlanVariantsChoices(
            event.detail.value,
            !appInstance.loading.planModal
          );
        },
        false
      );
    },
    async setPlanVariantsChoices(plan_id, removeActive) {
      const appInstance = this;
      const id = "plan-variant-choices";
      const element = document.getElementById(id);
      this.planVariantsChoices = [
        {
          value: "",
          label: "Select plan first",
          id: "",
        },
      ];
      if (plan_id > 0) {
        await API.getPlanVariants(plan_id)
          .then((res) => {
            appInstance.planVariantsChoices = [];
            const data = res.data.data;
            for (let i in data) {
              appInstance.planVariantsChoices.push({
                id: data[i].id,
                value: data[i].id,
                label: data[i].name,
              });
            }
            if (removeActive) {
              appInstance.plan.plan_variant_id = "";
              element._choices.removeActiveItems();
            }
            initChoices(
              id,
              { choices: appInstance.planVariantsChoices },
              appInstance.plan.plan_variant_id
            );
          })
          .catch((err) => {
            const response = handleError(err);
            showMessage(response.message, "error");
          });
      } else {
        await initChoices(id, { choices: this.planVariantsChoices });
      }
    },

    async savePlan() {
      if (this.formSubmitted.savePlan) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      this.formSubmitted.savePlan = true;
      let formData = new FormData();
      for (let key in this.plan) {
        if (key != "id" && key != "meta") {
          let value = this.plan[key];
          if (value === null) {
            value = "";
          }
          formData.append(key, value);
        }
      }
      for (let i in this.deliveryDays) {
        if (this.deliveryDays[i].delivery) {
          formData.set(
            "meta[preferred_days][" + i + "]",
            this.deliveryDays[i].day
          );
        }
      }
      let saveResponse = { status: 0 };
      let message = "";
      if (this.plan.id > 0) {
        formData.set("_method", "PATCH");
        saveResponse = await API.updateClientPlanDetails(
          this.client.id,
          this.plan.id,
          formData
        ).catch(handleError);
      } else {
        saveResponse = await API.createClientPlanDetails(
          this.client.id,
          formData
        ).catch(handleError);
      }
      let messageTimer = 2500;
      if (saveResponse.status === 200) {
        let planModal = this.$store.state.bootstrap.Modal.getInstance(
          document.getElementById("plan-modal")
        );
        planModal.hide();
        if (this.plan.id > 0) {
          await this.setClientPlans();
        } else {
          this.$router.push({
            name: "Client Plan",
            params: {
              id: this.client.id,
              planId: saveResponse.data.data.id,
            },
          });
        }
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Client plan details saved successfully." +
          "</span>";
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          saveResponse.message +
          "</span>";
        messageTimer = 3500;
      }
      showMessage(message, "", messageTimer, "", "html");
      this.formSubmitted.savePlan = false;
    },

    async deleteDislikeExclusion(dislike_id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.formSubmitted.deleteDislikeExclusion) {
            showMessage("Deleting dislike exclusion. Please wait", "", 1500);
          }
          this.formSubmitted.deleteDislikeExclusion = true;
          let response = await API.deleteClientDislikeExclusion(
            this.client.id,
            dislike_id
          ).catch(handleError);
          let message = "";
          let messageTimer = 2500;
          if (response.status === 200) {
            await this.setClientDislikeExclusions();
            message =
              '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
              "Client dislike exclusion deleted successfully." +
              "</span>";
          } else {
            message =
              '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
              response.message +
              "</span>";
            messageTimer = 3500;
          }
          showMessage(message, "", messageTimer, "", "html");
          this.formSubmitted.deleteDislikeExclusion = false;
        }
      });
    },
    async deleteDislike(dislike_id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.formSubmitted) {
            showMessage("Deleting dislike. Please wait.", "", 1500);
          }
          this.formSubmitted = true;
          let response = await API.deleteClientDislike(
            this.client.id,
            dislike_id
          ).catch(handleError);
          if (response.status === 200) {
            this.setClientDislikes();
            showMessage("Client dislike deleted successfully.", "success");
          } else {
            showMessage(response.message, "error");
          }
          this.formSubmitted = false;
        }
      });
    },
    async setClientRemarks(id) {
      this.client.remarks = [];
      const response = await API.getClientRemarks(id).catch(handleError);
      if (response.status == 200) {
        this.client.remarks = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async setClientDeliveryPreferences() {
      const response = await ApiClientDeliveryPreferences.index(
        this.client.id
      ).catch(handleError);
      if (response.status === 200) {
        this.clientDeliveryPreferences = response.data.data;
        for (let i in this.clientDeliveryPreferences) {
          this.clientDeliveryPreferences[i].delivery =
            this.clientDeliveryPreferences[i].delivery == 1;
        }
      } else {
        showMessage(response.message, "error");
      }
    },
    defaultDeliveryDays() {
      return [
        { day: 0, day_text: "Sunday", delivery: true },
        { day: 1, day_text: "Monday", delivery: true },
        { day: 2, day_text: "Tuesday", delivery: true },
        { day: 3, day_text: "Wednesday", delivery: true },
        { day: 4, day_text: "Thursday", delivery: true },
        { day: 5, day_text: "Friday", delivery: true },
        { day: 6, day_text: "Saturday", delivery: true },
      ];
    },
    getClientDeliveryDays() {
      let deliveryDays = this.defaultDeliveryDays();
      if (this.clientDeliveryPreferences.length > 0) {
        deliveryDays = [];
        for (let i in this.clientDeliveryPreferences) {
          deliveryDays.push({
            day: this.clientDeliveryPreferences[i].day,
            day_text: this.clientDeliveryPreferences[i].day_text,
            delivery: this.clientDeliveryPreferences[i].delivery,
          });
        }
      }
      if (this.plan.id > 0) {
        if (!this.plan.meta.preferred_days) {
          this.plan.meta.preferred_days = [];
        }
        for (let i in deliveryDays) {
          deliveryDays[i].delivery = Object.values(
            this.plan.meta.preferred_days
          ).includes(deliveryDays[i].day.toString());
        }
      }
      return deliveryDays;
    },
    async setClientConsultations() {
      await API.getListsConsultations(this.client.id)
        .then((res) => {
          this.client.consultations = [];
          if (res.data.data.length > 0) {
            this.client.consultations = res.data.data;
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
  },
};
</script>
<style>
li a {
  cursor: pointer;
}
</style>
