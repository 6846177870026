const state = {
  discounts: {
    showPlanVariants: true,
    showFilterPlans: false,
  },
};

const mutations = {
  setSetting(state, { path, value }) {
    const keys = path.split(".");
    let target = state;

    for (let i = 0; i < keys.length - 1; i++) {
      target = target[keys[i]];
    }

    target[keys[keys.length - 1]] = value;
  },
};

const actions = {
  updateSetting({ commit }, { path, value }) {
    commit("setSetting", { path, value });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
