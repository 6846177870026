<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-12">
        <div class="card widget-calendar widget-calendar-week">
          <div v-show="!loading.planTypeChoices" class="p-3 pb-0 card-header">
            <div class="row">
              <div class="col-sm-4 mt-3">
                <select
                  id="select-plan"
                  v-model="selectedPlanType.value"
                ></select>
              </div>
              <div class="col-sm-8 mt-3">
                <router-link
                  v-if="$can('create', 'substitute_menus')"
                  :to="{
                    name: 'Substitute Menus Copy',
                    params: { id: selectedPlanType.value },
                  }"
                  class="btn btn-outline-success btn-sm"
                >
                  Copy {{ selectedPlanType.name }} Substitute Menu
                </router-link>
              </div>
            </div>
          </div>
          <div class="p-3 card-body">
            <full-calendar
              :id="calendarId"
              ref="fullCalendar"
              :options="calendarOptions"
            >
              <template #eventContent="info">
                <div
                  v-if="info.view.type == 'dayGridWeek'"
                  :class="
                    'fc-event-main fc-event-main-' +
                    info.event.extendedProps.mealTypeCode
                  "
                >
                  <recipe-macros-info
                    v-if="info.event.extendedProps.macros"
                    class=""
                    :calories="info.event.extendedProps.macros.calories"
                    calories-text="Kcal"
                    :two-lines="true"
                    :carbs="info.event.extendedProps.macros.carbs"
                    :fat="info.event.extendedProps.macros.fat"
                    :protein="info.event.extendedProps.macros.protein"
                    protein-text="Pro"
                  />
                  <div v-else class="fc-event-main-frame">
                    <div class="fc-event-title-container">
                      <div class="fc-event-title fc-sticky">
                        <div class="text-xs">
                          {{ info.event.extendedProps.mealTypeName }}
                        </div>
                        <div>
                          {{ info.event.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="info.view.type == 'dayGridMonth'"
                  :class="
                    'fc-event-main fc-event-main-' +
                    info.event.extendedProps.mealTypeCode
                  "
                >
                  <recipe-macros-info
                    v-if="info.event.extendedProps.macros"
                    class=""
                    :calories="info.event.extendedProps.macros.calories"
                    calories-text="Kcal"
                    :two-lines="true"
                    :carbs="info.event.extendedProps.macros.carbs"
                    :fat="info.event.extendedProps.macros.fat"
                    :protein="info.event.extendedProps.macros.protein"
                    protein-text="Pro"
                  />
                </div>
                <div v-else class="fc-event-main">
                  <div class="fc-event-main-frame">
                    <div class="fc-event-title-container">
                      <div class="fc-event-title fc-sticky">
                        {{ info.event.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </full-calendar>
          </div>
        </div>
      </div>
    </div>
    <div id="substitute-menu" class="row mt-4">
      <div class="col-12">
        <loading-spinner
          :loading="selectedDate.length > 0 && loading.substituteMenuItems"
        />
        <div v-show="!loading.substituteMenuItems" class="card mt-4">
          <div class="py-3 card-header d-flex align-items-center">
            <div class="d-flex align-items-center">
              <h5>Substitute Menu for {{ dateFormat(selectedDate) }}</h5>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="nav-wrapper position-relative">
              <ul
                id="substitute-nav"
                class="p-1 bg-transparent nav nav-pills"
                role="tablist"
              >
                <li
                  v-for="(el, key) in mealTypes"
                  :id="'nav-item-' + el.name"
                  :key="key"
                  class="nav-item"
                >
                  <a
                    :id="'nav-' + el.name"
                    :class="
                      'px-0 py-1 mb-0 nav-link' +
                      (el.name === 'BF' ? ' active' : '')
                    "
                    data-bs-toggle="tab"
                    :data-bs-target="'#tab-content-items-' + el.name"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="ms-1">{{ el.value }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div id="items-tab-content" class="tab-content">
              <div
                v-for="(mealType, mealTypeKey) in mealTypes"
                :id="'tab-content-items-' + mealType.name"
                :key="mealTypeKey"
                :class="
                  'tab-pane fade' +
                  (mealType.name === 'BF' ? ' active show' : '')
                "
                role="tabpanel"
              >
                <div
                  v-for="(el, index) in substituteMenuItemsGroup[mealType.name]"
                  :key="index"
                  class="row mt-3"
                >
                  <input v-model="el.meal_type" type="hidden" />
                  <div class="col-sm-5 col-12">
                    <label
                      class="form-label"
                      :for="getElementRecipeId(mealType.name, index)"
                      >Recipe
                    </label>
                    <div class="form-group">
                      <select
                        :id="getElementRecipeId(mealType.name, index)"
                        v-model="el.recipe_id"
                        class="form-control item"
                      ></select>
                    </div>
                  </div>

                  <div class="col">
                    <label
                      :for="'item-is-premium' + mealType.name + '-' + index"
                      >Premium</label
                    >
                    <input
                      :id="'item-premium' + mealType.name + '-' + index"
                      v-model="el.premium"
                      class="form-control"
                      name="el_premium[]"
                    />
                  </div>
                  <div class="col">
                    <label
                      :for="'item-is-public' + mealType.name + '-' + index"
                    >
                      Is public
                    </label>
                    <argon-switch
                      :id="'item-is-public' + mealType.name + '-' + index"
                      v-model:checked="el.is_public"
                      name="el_is_public[]"
                    />
                  </div>
                  <div class="col-sm-2 col-12">
                    <span
                      v-if="el.id > 0"
                      class="text-success text-gradient mt-2 me-3 mb-0 d-inline-block cursor-pointer"
                      @click="updateItem(mealType.name, index)"
                    >
                      <i class="far fa-save me-2" aria-hidden="true"></i>Update
                    </span>
                    <span
                      v-if="el.id === 0"
                      class="text-success text-gradient mt-2 me-3 mb-0 d-inline-block cursor-pointer"
                      @click="createItem(mealType.name, index)"
                    >
                      <i class="far fa-save me-2" aria-hidden="true"></i>Create
                    </span>
                    <span
                      class="text-danger text-gradient mt-2 mb-0 d-inline-block cursor-pointer"
                      @click="deleteItem(mealType.name, index)"
                    >
                      <i class="far fa-trash-alt me-2" aria-hidden="true"></i
                      >Delete
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="text-end ms-auto">
                    <argon-button
                      class="mb-0"
                      color="success"
                      variant="gradient"
                      size="sm"
                      @click="addItem(mealType.name)"
                      >Add {{ mealType.value }}
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/api";
import ApiMiscList from "@/services/apiMiscList";
import ApiSubstituteMenu from "@/services/apiSubstituteMenu";
import ApiRecipes from "@/services/apiRecipes";
import { showMessage } from "@/assets/js/show-message";
import LoadingSpinner from "@/components/LoadingSpinner";
import "flatpickr/dist/flatpickr.css";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import {
  formatDataToChoicesJs,
  initChoices,
  setChoiceByValue,
} from "@/assets/js/init-choices";
import { handleError } from "@/lib/helpers";
import { dateFormat, previousDate } from "@/lib/dateHelper";
import setNavPills from "@/assets/js/nav-pills";
import ArgonSwitch from "@/components/ArgonSwitch";
import ArgonButton from "@/components/ArgonButton";
import RecipeMacrosInfo from "@/components/RecipeMacrosInfo";
import {
  getEventBgColor,
  getHourFromSlotLabelsByName,
  getSlotLabelsByHour,
} from "@/lib/fullCalendarHelper";

export default {
  name: "SubstituteMenus",
  components: {
    LoadingSpinner,
    ArgonButton,
    ArgonSwitch,
    FullCalendar,
    RecipeMacrosInfo,
  },
  data() {
    return {
      loading: {
        planTypeChoices: true,
        substituteMenuItems: true,
        substituteMenuItemsGroup: true,
      },
      formSubmitted: false,
      calendarId: "calendar-substitute-menu",
      planTypeChoices: [],
      selectedPlanType: {
        value: "International",
        name: "INTERNATIONAL",
      },
      selectedDate: dateFormat(new Date().toString(), "YYYY-MM-DD"),
      substituteMenuItems: [],
      substituteMenuItemsGroup: [],
      mealTypes: [],
      recipes: [],
      recipeChoices: [],
      weekEvents: [],
      weekMenuItems: [],
      monthStartDate: "",
      monthEndDate: "",
      monthEvents: [],
      monthMenuItems: {},
      slotLabelsByHour: [],
      calendarOptions: {
        firstDay: 1,
        contentHeight: "auto",
        plugins: [dayGridPlugin, interactionPlugin, multiMonthPlugin],
        initialView: "dayGridMonth",
        initialDate: this.selectedDate,
        selectable: true,
        editable: true,
        events: [],
        eventClick: this.calendarEventClick,
        dateClick: this.calendarDateClick,
        datesSet: this.datesSet,
        eventsSet: this.eventsSet,
        headerToolbar: {
          start: "title", // will normally be on the left. if RTL, will be on the right
          center: "",
          end: "today prev,next dayGridWeek,dayGridMonth,multiMonthYear", // will normally be on the right. if RTL, will be on the left
        },
        multiMonthMaxColumns: 6,
        multiMonthMinWidth: 180,
      },
      searchTimeOut: null,
    };
  },
  computed: {
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },
    selectedDateMacros() {
      return this.getTotalMacros(this.cycleMenuItems);
    },
  },
  async mounted() {
    await this.setPlanTypeChoices();
    this.loading.planTypeChoices = false;
    await this.initPlanTypeChoices();
    await this.setMealTypes();
    this.slotLabelsByHour = await getSlotLabelsByHour(this.mealTypes);
    await this.$nextTick();
    setNavPills();
  },
  methods: {
    dateFormat,
    getElementRecipeId(meal_code, index) {
      return "recipe" + meal_code + "-" + index;
    },
    async initAllRecipeChoices() {
      for (let i in this.mealTypes) {
        let meal_code = this.mealTypes[i].name;
        for (let index in this.substituteMenuItemsGroup[meal_code]) {
          await this.initRecipeChoices(meal_code, index);
        }
      }
    },
    async initRecipeChoices(meal_code, index) {
      const appInstance = this;
      const id = this.getElementRecipeId(meal_code, index);
      const element = document.getElementById(id);
      await initChoices(id, {
        choices: [],
        searchEnabled: true,
        searchFloor: 2,
      });
      if (this.substituteMenuItemsGroup[meal_code][index].recipe_id) {
        await element._choices.setChoices(
          async () => {
            try {
              return [
                {
                  value:
                    this.substituteMenuItemsGroup[meal_code][index].recipe_id,
                  label: ApiRecipes.getRecipeLabel(
                    this.substituteMenuItemsGroup[meal_code][index][
                      "recipe.name"
                    ],
                    this.substituteMenuItemsGroup[meal_code][index][
                      "recipe.allergens"
                    ],
                    this.substituteMenuItemsGroup[meal_code][index][
                      "recipe.macros"
                    ]
                  ),
                },
              ];
            } catch (err) {
              console.error(err);
            }
          },
          "value",
          "label",
          true
        );
      }
      setChoiceByValue(
        id,
        appInstance.substituteMenuItemsGroup[meal_code][index].recipe_id
      );
      element.addEventListener(
        "search",
        (event) => this.handleSearchRecipe(event, id),
        false
      );
      element.addEventListener(
        "change",
        async function (event) {
          let item = appInstance.recipes.find(
            (el) => el.id == event.detail.value
          );
          if (!item) {
            item = { "recipe.macros": "" };
          }
          appInstance.substituteMenuItemsGroup[meal_code][index][
            "recipe.macros"
          ] = item["recipe.macros"];
        },
        false
      );
    },
    handleSearchRecipe(event, id) {
      clearTimeout(this.searchTimeOut);
      const element = document.getElementById(id);
      if (element && element._choices) {
        element._choices.setChoices(
          [{ value: "", label: "Searching...", disabled: true }],
          "value",
          "label",
          true
        );
      }
      this.searchTimeOut = setTimeout(async () => {
        const response = await this.getRecipes(event.detail.value).catch(
          handleError
        );
        if (response.status === 200) {
          this.recipes = ApiRecipes.format(response.data.data);
          await initChoices(id, {
            choices: formatDataToChoicesJs(this.recipes),
            searchFloor: 0,
            searchChoices: false,
          });
        } else {
          showMessage(response.message, "error");
        }
      }, 1000);
    },
    async getRecipes(search_value) {
      let params =
        "draw=10&columns[0][data]=id&columns[0][name]=id&columns[0][searchable]=true&columns[0][orderable]=true" +
        "&columns[0][search][value]=&columns[0][search][regex]=false" +
        "&columns[1][data]=name&columns[1][name]=name&columns[1][searchable]=true&columns[1][orderable]=true&columns[1][search][value]=" +
        "&columns[1][search][regex]=false&start=0&length=150&search[value]=" +
        search_value;
      return await ApiRecipes.dropdown(params).catch(handleError);
    },
    async setMealTypes() {
      this.mealTypes = [];
      let response = await ApiMiscList.substituteMenuMealTypes().catch(
        handleError
      );
      if (response.status === 200) {
        this.mealTypes = response.data.data;
      } else {
        showMessage(response.message, "error", 5000);
      }
    },
    async setPlanTypeChoices() {
      await API.getPlanTypes()
        .then((res) => {
          this.planTypeChoices = formatDataToChoicesJs(
            res.data.data,
            "no empty option",
            {
              id: "value",
              value: "value",
              label: "name",
            }
          );
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initPlanTypeChoices() {
      const id = "select-plan";
      const element = document.getElementById(id);
      await initChoices(
        id,
        {
          choices: this.planTypeChoices,
          placeholder: false,
          removeItemButton: false,
        },
        this.selectedPlanType.value,
        this.selectedPlanType.name
      );
      element.addEventListener("change", async (event) => {
        let plan = this.planTypeChoices.find(
          (x) => x.value === event.detail.value
        );
        this.selectedPlanType.name = plan.label;
        if (this.selectedDate.length > 0) {
          await this.setSubstituteMenuItems(
            this.selectedPlanType.value,
            this.selectedDate,
            this.selectedDate
          );
        }
      });
    },
    async calendarDateClick(info) {
      if (info.view.type == "multiMonthYear") {
        this.calendarApi.gotoDate(info.dateStr);
        this.calendarApi.changeView("dayGridMonth");
      }
      this.selectedDate = info.dateStr;
      await this.setSubstituteMenuItems(
        this.selectedPlanType.value,
        this.selectedDate,
        this.selectedDate
      );
    },
    async calendarEventClick(info) {
      let eventDate = dateFormat(info.event.start, "YYYY-MM-DD");
      let substituteMenuEl = document.getElementById("substitute-menu");
      substituteMenuEl.scrollIntoView();
      await this.$nextTick();
      if (eventDate != this.selectedDate) {
        this.selectedDate = eventDate;
        this.loading.substituteMenuItems = true;
        this.substituteMenuItems = this.weekMenuItems[this.selectedDate];
        if (!this.substituteMenuItems) {
          await this.setSubstituteMenuItems(
            this.selectedPlanType.value,
            this.selectedDate,
            this.selectedDate
          );
        } else {
          await this.setSubstituteMenuItemsGroup(this.substituteMenuItems);
        }
        this.loading.substituteMenuItems = false;
      }
      let navItemElement = document.getElementById(
        "nav-" + info.event.extendedProps.mealTypeCode
      );
      let navElement = document.getElementById("substitute-nav");

      setTimeout(function () {
        navItemElement.click();
        navElement.scrollIntoView();
      }, 10);
    },
    async eventsSet() {
      await this.$nextTick();
      this.mealTypes.forEach((mealType) => {
        let elements = document.querySelectorAll(
          ".fc-event-main-" + mealType.name
        );
        let maxHeight = 0;
        elements.forEach((element) => {
          maxHeight = Math.max(maxHeight, element.offsetHeight);
        });
        elements.forEach((element) => {
          element.style.height = maxHeight + "px";
        });
      });
    },
    async datesSet(info) {
      this.loading.substituteMenuItems = true;
      this.calendarApi.getEventSources().forEach((eventSource) => {
        eventSource.remove();
      });
      let startDate = dateFormat(info.view.currentStart, "YYYY-MM-DD");
      let endDate = dateFormat(
        previousDate(info.view.currentEnd),
        "YYYY-MM-DD"
      );
      if (info.view.type == "dayGridWeek") {
        if (startDate != this.weekStartDate || endDate != this.weekEndDate) {
          this.weekStartDate = startDate;
          this.weekEndDate = endDate;
          this.weekEvents = await this.getWeekEvents(
            this.selectedPlanType.value,
            this.weekStartDate,
            this.weekEndDate
          );
        }
        this.calendarApi.addEventSource(this.weekEvents);
      }
      if (info.view.type == "dayGridMonth") {
        if (startDate != this.monthStartDate || endDate != this.monthEndDate) {
          this.monthStartDate = startDate;
          this.monthEndDate = endDate;
          this.monthEvents = await this.getMonthEvents(
            this.selectedPlanType.value,
            this.monthStartDate,
            this.monthEndDate
          );
          if (
            Object.keys(this.substituteMenuItems).length === 0 &&
            this.monthMenuItems[this.selectedDate]
          ) {
            this.substituteMenuItems = this.monthMenuItems[this.selectedDate];
            await this.setSubstituteMenuItemsGroup(this.substituteMenuItems);
          }
        }
        this.calendarApi.addEventSource(this.monthEvents);
      }
      this.loading.substituteMenuItems = false;
    },
    async getMonthEvents(plan_type, startDate, endDate) {
      this.loading.substituteMenuItems = true;

      const response = await ApiSubstituteMenu.get(
        plan_type,
        startDate,
        endDate
      ).catch((err) => handleError(err));
      let events = [];
      this.monthMenuItems = {};
      if (response.status === 200) {
        for (let i in response.data.data) {
          let menuItem = response.data.data[i];
          if (!this.monthMenuItems[menuItem.menu_date]) {
            this.monthMenuItems[menuItem.menu_date] = [];
          }
          this.monthMenuItems[menuItem.menu_date].push(menuItem);
        }
        for (let i in this.monthMenuItems) {
          events.push({
            title: "Total",
            start: i,
            end: i,
            className: "py-1  ",
            extendedProps: {
              type: "event",
              macros: this.getTotalMacros(this.monthMenuItems[i]),
              mealTypeCode: "BF",
            },
          });
        }
      } else {
        showMessage(response.message, "error");
      }
      this.loading.substituteMenuItems = false;
      return events;
    },
    getTotalMacros(items) {
      let result = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      };
      for (let i in items) {
        result.calories += parseFloat(items[i]["recipe.macros"].calories);
        result.carbs += parseFloat(items[i]["recipe.macros"].carbs);
        result.fat += parseFloat(items[i]["recipe.macros"].fat);
        result.protein += parseFloat(items[i]["recipe.macros"].protein);
      }
      return result;
    },
    async getWeekEvents(plan_type, start_date, end_date) {
      this.loading.substituteMenuItems = true;

      const response = await ApiSubstituteMenu.get(
        plan_type,
        start_date,
        end_date
      ).catch((err) => handleError(err));
      let events = [];
      if (response.status === 200) {
        const seconds = {};
        for (let i in response.data.data) {
          let menuItem = response.data.data[i];
          if (!this.weekMenuItems[menuItem.menu_date]) {
            this.weekMenuItems[menuItem.menu_date] = [];
          }
          this.weekMenuItems[menuItem.menu_date].push(menuItem);
        }
        for (let menu_date in this.weekMenuItems) {
          let dayMenuItems = this.weekMenuItems[menu_date];
          events.push({
            title: "Total",
            start: menu_date,
            end: menu_date,
            className: "py-1  ",
            extendedProps: {
              type: "event",
              macros: this.getTotalMacros(dayMenuItems),
              mealTypeCode: "BF",
            },
          });
          for (let i in dayMenuItems) {
            let menuItem = dayMenuItems[i];
            if (!seconds[menuItem.meal_type]) {
              seconds[menuItem.meal_type] = 2;
            } else {
              seconds[menuItem.meal_type]++;
            }
            for (let mealTypeKey in this.mealTypes) {
              const mealType = this.mealTypes[mealTypeKey];
              const mealTypeCode = menuItem.meal_type;
              if (mealTypeCode != mealType.name) {
                continue;
              }
              const mealTypeName = this.getMealTypeValueByName(mealTypeCode);
              const hour = getHourFromSlotLabelsByName(
                mealTypeName,
                this.slotLabelsByHour
              );
              const menu_date = this.getMenuDate(
                menuItem.menu_date + " " + hour + ":00:00",
                seconds[menuItem.meal_type]
              );
              let event = {
                id: menuItem.id,
                title: menuItem["recipe.name"],
                start: menu_date,
                end: menu_date,
                className: "py-1  " + getEventBgColor(mealTypeCode),
                extendedProps: {
                  type: "event",
                  mealTypeName: mealTypeName,
                  mealTypeCode: mealTypeCode,
                  menuItem: menuItem,
                },
              };
              events.push(event);
            }
          }
        }
      } else {
        showMessage(response.message, "error");
      }
      this.loading.substituteMenuItems = false;
      return events;
    },
    getMenuDate(dateTimeStr, seconds) {
      let date = new Date(dateTimeStr);
      date.setSeconds(date.getSeconds() + seconds);
      return date;
    },
    getMealTypeValueByName(name) {
      let item = this.mealTypes.find((x) => x.name === name);
      if (item) {
        return item.value;
      }
      return name;
    },
    async setSubstituteMenuItems(plan_type, start_date, end_date) {
      this.loading.substituteMenuItems = true;
      let response = await ApiSubstituteMenu.get(
        plan_type,
        start_date,
        end_date
      ).catch(handleError);
      if (response.status === 200) {
        this.substituteMenuItems = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
      await this.setSubstituteMenuItemsGroup(this.substituteMenuItems);
      this.loading.substituteMenuItems = false;
    },
    async setSubstituteMenuItemsGroup(items) {
      this.loading.substituteMenuItemsGroup = true;
      this.substituteMenuItemsGroup = {};
      for (let i in items) {
        if (!this.substituteMenuItemsGroup[items[i]["meal_type"]]) {
          this.substituteMenuItemsGroup[items[i]["meal_type"]] = [];
        }
        this.substituteMenuItemsGroup[items[i]["meal_type"]].push(items[i]);
      }
      this.loading.substituteMenuItemsGroup = false;
      await this.$nextTick();
      await this.initAllRecipeChoices();
    },
    async createItem(meal_type, index) {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      let item = this.substituteMenuItemsGroup[meal_type][index];
      this.formSubmitted = true;
      let formData = new FormData();
      formData.append("recipe_id", item.recipe_id);
      formData.append("meal_type", meal_type);
      formData.append("menu_date", this.selectedDate);
      formData.append("is_public", item.is_public ? 1 : 0);
      formData.append("premium", item.premium);

      let saveResponse = await ApiSubstituteMenu.create(
        this.selectedPlanType.value,
        formData
      ).catch(handleError);

      if (saveResponse.status === 200) {
        this.substituteMenuItemsGroup[meal_type][index]["id"] =
          saveResponse.data.data.id;
        showMessage("Substitute Menu item created successfully.", "success");
      } else {
        showMessage(saveResponse.message, "error");
      }
      this.formSubmitted = false;
    },
    async updateItem(meal_type, index) {
      if (this.formSubmitted) {
        showMessage("Saving data. Please wait.", "", 1500);
        return;
      }
      let item = this.substituteMenuItemsGroup[meal_type][index];
      this.formSubmitted = true;
      let formData = new FormData();
      formData.append("recipe_id", item.recipe_id);
      formData.append("meal_type", meal_type);
      formData.append("menu_date", this.selectedDate);
      formData.append("is_public", item.is_public ? 1 : 0);
      formData.append("premium", item.premium);
      formData.append("_method", "PATCH");

      let saveResponse = await ApiSubstituteMenu.update(
        this.selectedPlanType.value,
        item.id,
        formData
      ).catch(handleError);

      if (saveResponse.status === 200) {
        showMessage("Substitute Menu item updated successfully.", "success");
      } else {
        showMessage(saveResponse.message, "error");
      }
      this.formSubmitted = false;
    },
    async deleteItem(meal_type, index) {
      if (this.formSubmitted) {
        showMessage("Deleting data. Please wait.", "", 1500);
        return;
      }
      let item = this.substituteMenuItemsGroup[meal_type][index];
      if (item.id > 0) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-danger ms-2",
            cancelButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let item = this.substituteMenuItemsGroup[meal_type][index];
            this.formSubmitted = true;
            let saveResponse = await ApiSubstituteMenu.delete(
              this.selectedPlanType.value,
              item.id
            ).catch(handleError);

            if (saveResponse.status === 200) {
              await this.setSubstituteMenuItems(
                this.selectedPlanType.value,
                this.selectedDate,
                this.selectedDate
              );
              showMessage(
                "Substitute Menu item deleted successfully.",
                "success"
              );
            } else {
              showMessage(saveResponse.message, "error");
            }
            this.formSubmitted = false;
          }
        });
      } else {
        this.substituteMenuItemsGroup[meal_type].splice(index, 1);
      }
    },
    async addItem(meal_code) {
      if (!this.substituteMenuItemsGroup[meal_code]) {
        this.substituteMenuItemsGroup[meal_code] = [];
      }
      this.substituteMenuItemsGroup[meal_code].push({
        id: 0,
        is_public: false,
        premium: 0,
        "recipe.name": "",
        recipe_id: "",
      });
      await this.$nextTick();
      await this.initRecipeChoices(
        meal_code,
        this.substituteMenuItemsGroup[meal_code].length - 1
      );
    },
    getMealTypeName(meal_code) {
      let obj = this.mealTypes.find((x) => x.name === meal_code);
      return obj.value;
    },
  },
};
</script>
