<template>
  <GradientLineChart
    v-if="chartData"
    id="sleep-time"
    :key="chartData.labels.join(',')"
    :legend="true"
    :y-axis="true"
    :chart="chartData"
    class="h-100"
    :loading="loading"
  >
    <HealthHeader
      :selected-date="startDate"
      title="Sleep Time"
      @update-graph="updateGraph"
    />
    <HealthFilter
      :interval="interval"
      :loading="loading"
      @filter-by="updateGraph"
    />
    <HealthNavigator
      :start-date="startDate"
      :end-date="endDate"
      :today="today"
      :interval="interval"
      :loading="loading"
      @update-graph="updateGraph"
    />
    <template #displayData>
      <ResultDisplay v-if="avg_remSleep !== 0" title="Average Rem Sleep">
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            <span class="font-bold text-red-500">{{ avg_remSleep }}Hrs </span>
          </div>
        </div>
      </ResultDisplay>
      <ResultDisplay v-if="avg_deepSleep !== 0" title="Average Deep Sleep">
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            <span class="font-bold text-red-500">{{ avg_deepSleep }}Hrs </span>
          </div>
        </div>
      </ResultDisplay>
      <ResultDisplay v-if="avg_lightSleep !== 0" title="Average Light Sleep">
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            <span class="font-bold text-red-500">{{ avg_lightSleep }}Hrs </span>
          </div>
        </div>
      </ResultDisplay>
      <ResultDisplay v-if="avg_napSleep !== 0" title="Average Nap Sleep">
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            <span class="font-bold text-red-500">{{ avg_napSleep }}Hrs </span>
          </div>
        </div>
      </ResultDisplay>
      <ResultDisplay v-if="totalSleep !== 0" title="Total Sleep">
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            <span class="font-bold text-red-500">{{ totalSleep }}Hrs </span>
          </div>
        </div>
      </ResultDisplay>
    </template>
  </GradientLineChart>
</template>
<script>
import GradientLineChart from "@/components/GradientLineChart.vue";
import apiHealth from "@/services/apiHealth";
import HealthFilter from "./components/Filter.vue";
import ResultDisplay from "./components/ResultDisplay.vue";
import { dateFormat } from "@/lib/dateHelper";
import HealthNavigator from "./components/HealthNavigator.vue";
import HealthHeader from "./components/HealthHeader.vue";
export default {
  name: "SleepTime",
  components: {
    GradientLineChart,
    HealthFilter,
    ResultDisplay,
    HealthNavigator,
    HealthHeader,
  },
  data() {
    return {
      interval: "day",
      chartData: "",
      avg_remSleep: "",
      avg_deepSleep: "",
      avg_lightSleep: "",
      avg_napSleep: "",
      totalSleep: "",
      today: dateFormat("today", "YYYY-MM-DD"),
      startDate: "",
      endDate: "",
      loading: false,
    };
  },
  async mounted() {
    this.fetchBP(this.today, this.today);
  },
  methods: {
    async updateGraph(start, end, interval) {
      this.interval = interval;
      await this.fetchBP(start, end);
    },
    async fetchBP(start, end) {
      this.startDate = start;
      this.endDate = end;
      this.loading = true;
      try {
        const res = await apiHealth.getSleepTime(
          this.$route.params.id,
          start,
          end
        );
        if (res && res.data.graphData) {
          const labels = Object.keys(res.data.graphData);
          const displayLabels = labels.map((label) =>
            /^\d{4}-\d{2}$/.test(label)
              ? dateFormat(new Date(label + "-01"), { month: "short" })
              : label
          );
          const remSleep = labels.map(
            (time) => res.data.graphData[time].rem_sleep
          );
          const deepSleep = labels.map(
            (time) => res.data.graphData[time].deep_sleep
          );
          const lightSleep = labels.map(
            (time) => res.data.graphData[time].light_sleep
          );
          const napSleep = labels.map(
            (time) => res.data.graphData[time].nap_sleep
          );
          this.chartData = {
            labels: displayLabels,
            datasets: [
              {
                label: "Rem Sleep",
                data: remSleep,
              },
              {
                label: "Deep Sleep",
                data: deepSleep,
              },
              {
                label: "Light Sleep",
                data: lightSleep,
              },
              {
                label: "Nap Sleep",
                data: napSleep,
              },
            ],
          };
          if (res.data.average_rem_sleep !== null) {
            this.avg_remSleep = res.data.average_rem_sleep;
          } else {
            this.avg_remSleep = 0;
          }
          if (res.data.average_deep_sleep !== null) {
            this.avg_deepSleep = res.data.average_deep_sleep;
          } else {
            this.avg_deepSleep = 0;
          }
          if (res.data.average_light_sleep !== null) {
            this.avg_lightSleep = res.data.average_light_sleep;
          } else {
            this.avg_lightSleep = 0;
          }
          if (res.data.average_nap_sleep !== null) {
            this.avg_napSleep = res.data.average_nap_sleep;
          } else {
            this.avg_napSleep = 0;
          }
          if (res.data.total_sleep !== null) {
            this.totalSleep = res.data.total_sleep;
          } else {
            this.totalSleep = 0;
          }
        }
      } catch (error) {
        console.error("Error fetching sleep data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
