<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="$can('update', 'ingredients')"
            type="button"
            class="btn btn-outline-white"
            @click="updateIngredient"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Ingredient Image</h5>
            <div class="row">
              <div class="col-12">
                <img
                  class="mt-3 shadow-lg w-100 border-radius-lg"
                  :src="ingredient.image"
                  alt="ingredient image"
                  @click="showImagePicker"
                />

                <input
                  ref="file"
                  type="file"
                  style="display: none"
                  accept="image/png, image/gif, image/jpeg"
                  @change="selectImage"
                />
              </div>
              <div class="mt-5 col-12">
                <div class="d-flex">
                  <button
                    class="mb-0 btn bg-gradient-success btn-sm me-2"
                    type="button"
                    name="button"
                    @click="showImagePicker"
                  >
                    Upload
                  </button>
                  <button
                    v-if="ingredient.image != ingredientDefaultImage"
                    class="mb-0 btn btn-outline-dark btn-sm"
                    type="button"
                    name="button"
                    @click="removeImage"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Ingredient Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  id="ingredient-name"
                  v-model="ingredient.name"
                  class="form-control"
                  type="text"
                  placeholder="Ingredient name"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Type</label>
                <select
                  id="type"
                  v-model="ingredient.type"
                  class="form-control"
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Parent Ingredient</label>
                <select
                  id="parent-ingredient"
                  v-model="ingredient.parent_id"
                  class="form-control"
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Allergens</label>
                <select
                  id="ingredient-allergen"
                  v-model="ingredient.allergen_ids"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Status</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Active </label>
                  <argon-switch
                    id="ingredient-status-switch"
                    v-model:checked="ingredient.status"
                    name="ingredient_status"
                  />
                </div>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Is public</label>
                <div class="d-flex">
                  <label class="form-check-label mb-0 me-2"> Yes </label>
                  <argon-switch
                    id="ingredient-is-public-switch"
                    v-model:checked="ingredient.is_public"
                    name="ingredient_is_public"
                  />
                </div>
              </div>
              <div class="mt-3 col-12 mt-sm-0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showTable" class="card mt-4">
      <div class="card-header pb-0">
        <h6>
          Recipes that contain <i>{{ ingredient.name }}</i>
        </h6>
      </div>
      <div class="card-body">
        <data-table :options="datatable.options" :data="datatable.data" />
      </div>
    </div>
  </div>
</template>

<script>
import ingredientDefaultImage from "@/assets/img/recipe.png";
import API from "@/services/api";
import ApiAllergens from "@/services/apiAllergens";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message";
import { initChoices } from "@/assets/js/init-choices";
import apiIngredients from "@/services/apiIngredients";
import DataTable from "@/components/DataTable.vue";

export default {
  name: "Edit",
  components: {
    ArgonSwitch,
    DataTable,
  },
  data() {
    return {
      ingredientDefaultImage,
      ingredientImage: undefined,
      showTable: false,
      ingredient: {
        name: "",
        type: "",
        parent_id: "",
        parent_name: "",
        allergen_ids: "",
        allergen_name: "",
        image: "",
      },
      recipes: [],
      datatable: {
        options: {},
        data: [],
      },
    };
  },
  async mounted() {
    await this.setIngredient();
    await initChoices(
      "type",
      { choices: API.getIngredientTypeOptions() },
      this.ingredient.type,
      this.ingredient.type
    );
    this.initParentIngredientChoices();
    this.initIngredientAllergenChoices();
  },
  methods: {
    async initParentIngredientChoices() {
      await apiIngredients
        .get("start=0&length=-1")
        .then((res) => {
          initChoices(
            "parent-ingredient",
            { choices: API.formatDataToChoicesJs(res.data.data) },
            this.ingredient.parent_id
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initIngredientAllergenChoices() {
      await ApiAllergens.get("start=0&length=-1")
        .then((res) => {
          initChoices(
            "ingredient-allergen",
            { choices: API.formatDataToChoicesJs(res.data.data) },
            this.ingredient.allergen_ids
          );
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setIngredient() {
      const appInstance = this;
      await API.getIngredient(appInstance.$route.params.id)
        .then((res) => {
          this.ingredient = {
            name: res.data.data.name,
            type: res.data.data.type,
            parent_id: res.data.data.parent_id,
            parent_name: res.data.data.parent_name,
            allergen_ids: res.data.data.allergen_ids,
            allergen_name: res.data.data.allergen_name,
            status: res.data.data.status === "Active",
            is_public: res.data.data.is_public,
            image: this.ingredientDefaultImage,
          };
          if (res.data.data.image.length) {
            this.ingredient.image = res.data.data.image;
          }
          if (res.data.data.recipes.data.length) {
            this.recipes = res.data.data.recipes.data;
            this.setDatatable(this.recipes);
            this.showTable = true;
          }
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Ingredient not found",
              willClose: () => {
                appInstance.$router.push({ name: "Ingredients List" });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        });
    },

    async updateIngredient() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = new FormData();
      formData.append("name", this.ingredient.name);
      formData.append(
        "type",
        this.ingredient.type && this.ingredient.type !== "null"
          ? this.ingredient.type
          : ""
      );
      if (this.ingredient.parent_id > 0) {
        formData.append("parent_id", this.ingredient.parent_id);
      }
      for (let key in this.ingredient.allergen_ids) {
        formData.append("allergen_ids[]", this.ingredient.allergen_ids[key]);
      }
      formData.append(
        "is_public",
        this.ingredient.is_public === true ? "1" : "0"
      );
      formData.append(
        "status",
        this.ingredient.status === true ? "Active" : "Inactive"
      );
      formData.append("_method", "PATCH");
      if (this.ingredientImage) {
        formData.append("image", this.ingredientImage);
      }
      await API.updateIngredient(this.$route.params.id, formData)
        .then(() => {
          showMessage(
            "Success. Ingredient updated.",
            { name: "fa-check-circle", class: "text-success" },
            1500
          );
        })
        .catch((err) => {
          let message = err.message;
          if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
          }
          showMessage(
            message,
            { name: "fa-exclamation-triangle", class: "text-danger" },
            3500
          );
        });
      this.formSubmitted = false;
    },
    async deleteIngredient() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          API.deleteIngredient(this.$route.params.id)
            .then(() => {
              showMessage(
                "Success. Ingredient deleted.",
                { name: "fa-check-circle", class: "text-success" },
                1500,
                () => {
                  this.$router.push("/config/ingredients");
                }
              );
            })
            .catch((err) => {
              let message = err.message;
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                message = err.response.data.message;
              }
              showMessage(
                message,
                { name: "fa-exclamation-triangle", class: "text-danger" },
                3500
              );
            });
        }
      });
    },
    showImagePicker() {
      this.$refs.file.click();
    },
    selectImage() {
      this.ingredientImage = this.$refs.file.files.item(0);
      this.ingredient.image = URL.createObjectURL(this.ingredientImage);
    },
    removeImage() {
      this.ingredientImage = undefined;
      this.ingredient.image = this.ingredientDefaultImage;
    },

    setDatatable(data) {
      this.datatable = {
        options: {
          searching: true,
          info: true,
          paging: true,
          columns: [
            {
              title: "ID",
              data: "id",
              class: "text-xs",
            },
            {
              title: "Name",
              data: "name",
              class: "text-xs",
            },
            {
              title: "Type",
              data: "type",
              class: "text-xs",
            },
            {
              title: "Status",
              data: "status",
              class: "text-xs",
              render: (data) => {
                return (
                  '<span class="badge badge-dot me-4"><i class="' +
                  (data == "Active" ? "bg-success" : "bg-danger") +
                  '"></i><span class="text-dark text-xs">' +
                  data +
                  "</span></span>"
                );
              },
            },
            {
              title: "Calories",
              data: "calories",
              class: "text-xs",
            },
            {
              title: "Carbs",
              data: "carbs",
              class: "text-xs",
            },
            {
              title: "Fat",
              data: "fat",
              class: "text-xs",
            },
            {
              title: "Protein",
              data: "protein",
              class: "text-xs",
            },
            {
              title: "Actions",
              data: "id",
              class: "text-xs",
              render: (data) => {
                return (
                  '<a href="/config/recipes/' +
                  data +
                  '""class="ms-3"><i class="far fa-edit text-secondary"></i></a>'
                );
              },
            },
          ],
        },
        data: [],
      };
      data.forEach((item) => {
        this.datatable.data.push({
          id: item.id,
          name: item.name,
          type: item.type,
          status: item.status,
          calories: item["recipe.macros"]
            ? item["recipe.macros"].calories
            : "N/A",
          carbs: item["recipe.macros"] ? item["recipe.macros"].carbs : "N/A",
          fat: item["recipe.macros"] ? item["recipe.macros"].fat : "N/A",
          protein: item["recipe.macros"]
            ? item["recipe.macros"].protein
            : "N/A",
        });
      });
    },
  },
};
</script>
