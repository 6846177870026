<template>
  <div class="mb-4 card">
    <div class="row g-4 p-4">
      <div class="col-xxxl-4 col-lg-6 d-flex">
        <steps-count class="w-100" />
      </div>
      <div class="col-xxxl-4 col-lg-6 d-flex">
        <daily-intake class="w-100" />
      </div>
      <div class="col-xxxl-4 col-lg-6 d-flex">
        <distance-cover class="w-100" />
      </div>
      <div class="col-xxxl-4 col-lg-6 d-flex">
        <blood-pressure class="w-100" />
      </div>
      <div class="col-xxxl-4 col-lg-6 d-flex">
        <blood-oxygen class="w-100" />
      </div>
      <div class="col-xxxl-4 col-lg-6 d-flex">
        <body-temperature class="w-100" />
      </div>
      <div class="col-xxxl-4 col-lg-6 d-flex">
        <heart-rate class="w-100" />
      </div>
      <div class="col-xxxl-4 col-lg-6 d-flex">
        <sleep-time class="w-100" />
      </div>
    </div>
  </div>
</template>

<script>
import BloodPressure from "./BloodPressure.vue";
import BodyTemperature from "./BodyTemperature.vue";
import DistanceCover from "./DistanceCover.vue";
import HeartRate from "./HeartRate.vue";
import SleepTime from "./SleepTime.vue";
import BloodOxygen from "./BloodOxygen.vue";
import DailyIntake from "./DailyIntake.vue";
import StepsCount from "./StepsCount.vue";

export default {
  name: "HealthCards",
  components: {
    BloodPressure,
    BodyTemperature,
    DistanceCover,
    HeartRate,
    SleepTime,
    BloodOxygen,
    DailyIntake,
    StepsCount,
  },
};
</script>
