<template>
  <button
    v-if="$can('update', 'wallets')"
    class="btn btn-success text-white btn-sm mb-0"
    data-bs-toggle="modal"
    data-bs-target="#withdrawModal"
    @click="initData"
  >
    Withdraw
  </button>
  <div id="withdrawModal" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog mt-lg-10">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="ModalLabel" class="modal-title">Withdraw</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>Enter withdrawal details below.</p>
          <form id="withdrawForm">
            <div class="mb-3">
              <label for="amount" class="form-label">Amount</label>
              <input
                id="amount"
                v-model="data.amount"
                type="text"
                class="form-control"
                placeholder="Enter amount"
              />
            </div>
            <div class="mb-3">
              <label for="description" class="form-label">Description</label>
              <input
                id="description"
                v-model="data.description"
                type="text"
                class="form-control"
                placeholder="Enter description"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-gradient-secondary btn-sm"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="submit"
            :disabled="loading"
            class="btn bg-gradient-success btn-sm"
            @click="onSubmit"
          >
            Withdraw
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletWithdrawModal",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update"],
  data() {
    return {
      data: {
        amount: null,
        description: null,
      },
    };
  },
  methods: {
    onSubmit() {
      this.$emit("update", this.data);
    },
    initData() {
      this.data = {
        amount: null,
        description: null,
      };
    },
  },
};
</script>
