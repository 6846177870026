<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="text-right col-12 d-flex flex-column justify-content-center">
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{
              name: 'Client Profile',
              params: { id: clientId },
            }"
            class="btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">New address</h5>
            <div
              class="text-right col-lg-12 d-flex flex-column justify-content-center"
            >
              <loading-spinner :loading="loading.addresses" />
              <div v-if="!loading.addresses" class="row">
                <div class="col-12">
                  <client-address
                    v-model:id="clientAddress.id"
                    v-model:city-id="clientAddress.city_id"
                    v-model:area-id="clientAddress.area_id"
                    v-model:type="clientAddress.type"
                    v-model:street="clientAddress.street"
                    v-model:landmark="clientAddress.landmark"
                    v-model:building-name="clientAddress.building_name"
                    v-model:flat-number="clientAddress.flat_number"
                    v-model:latitude="clientAddress.latitude"
                    v-model:longitude="clientAddress.longitude"
                    v-model:notes="clientAddress.notes"
                    :active="'active show'"
                    :hide-status="true"
                    :area-name="clientAddress['area.name']"
                    :city-name="clientAddress['city.name']"
                    :client-id="clientId"
                    :address-areas-initial="addressAreas"
                    :address-cities="addressCities"
                    :address-types="addressTypes"
                    @create-client-address="createClientAddress($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientAddress from "@/views/pages/Clients/components/Address";
import ApiAddresses from "@/services/apiAddresses";
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: "Edit",
  components: {
    LoadingSpinner,
    ClientAddress,
  },
  data() {
    return {
      clientAddress: {},
      loading: {
        addresses: true,
      },
      formSubmitted: false,
      clientId: parseInt(this.$route.params.id),
      addressAreas: [],
      addressCities: [],
      addressTypes: [],
    };
  },
  async mounted() {
    this.addressAreas = await ApiAddresses.getAreas("start=0&length=-1");
    this.addressCities = await ApiAddresses.getCities("start=0&length=-1");
    this.addressTypes = ApiAddresses.addressTypeChoices();
    this.clientAddress = {
      id: 0,
      city_id: "",
      area_id: "",
      building_name: "",
      flat_number: "",
      landmark: "",
      latitude: process.env.VUE_APP_ADDRESS_LATITUDE,
      longitude: process.env.VUE_APP_ADDRESS_LONGITUDE,
      notes: "",
      street: "",
      type: "Home",
    };
    this.loading.addresses = false;
  },
  methods: {
    createClientAddress(data) {
      this.$router.push({
        name: "Edit Client Address",
        params: {
          id: this.clientId,
          addressId: data.id,
        },
      });
    },
  },
};
</script>
