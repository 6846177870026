import { httpApi } from "@/services/httpApi";

class apiHealth {
  async getBloodPressure(id, start_date, end_date) {
    return await httpApi.get(
      "api/v1/clients/" +
        id +
        "/blood-pressure/graph?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
  async getBodyTemperature(id, start_date, end_date) {
    return await httpApi.get(
      "api/v1/clients/" +
        id +
        "/body-temperature/graph?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
  async getDistanceCovered(id, start_date, end_date) {
    return await httpApi.get(
      "api/v1/clients/" +
        id +
        "/distance-coverage/graph?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
  async getHeartRate(id, start_date, end_date) {
    return await httpApi.get(
      "api/v1/clients/" +
        id +
        "/heart-rates/graph?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
  async getSleepTime(id, start_date, end_date) {
    return await httpApi.get(
      "api/v1/clients/" +
        id +
        "/sleep-time/graph?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
  async getOxygenLevel(id, start_date, end_date) {
    return await httpApi.get(
      "api/v1/clients/" +
        id +
        "/blood-oxygen/graph?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
  async getDailyIntake(id, start_date, end_date) {
    return await httpApi.get(
      "api/v1/clients/" +
        id +
        "/daily-intakes/graph?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
  async getStepCount(id, start_date, end_date) {
    return await httpApi.get(
      "api/v1/clients/" +
        id +
        "/step-count/graph?start_date=" +
        start_date +
        "&end_date=" +
        end_date
    );
  }
}

export default new apiHealth();
