<template>
  <div class="d-flex justify-content-between">
    <h6>{{ title }}</h6>
    <div class="text-end">
      <argon-date
        v-model="date"
        :show-icon-only="true"
        @update:model-value="handleDateUpdate"
      />
    </div>
  </div>
</template>

<script>
import ArgonDate from "@/components/ArgonDate.vue";

export default {
  name: "HealthHeader",
  components: {
    ArgonDate,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    selectedDate: {
      type: String,
      required: true,
    },
  },
  emits: ["update-graph"],
  data() {
    return {
      date: this.selectedDate,
    };
  },
  methods: {
    handleDateUpdate(newDate) {
      this.$emit("update-graph", newDate, newDate, "day");
    },
  },
};
</script>
