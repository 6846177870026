<template>
  <div class="card">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col d-flex align-items-center">
          <h6 class="mb-0 text-capitalize">Addresses</h6>
        </div>
        <div class="col-auto text-end">
          <router-link
            v-if="$can('create', 'addresses')"
            :to="{
              name: 'New Client Address',
              params: { id: $route.params.id },
            }"
            class="mb-0 btn btn-xs bg-gradient-success"
          >
            <i class="fas fa-plus pe-2" aria-hidden="true"></i> Add address
          </router-link>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li
          v-for="(address, index) of addresses"
          :key="index"
          class="list-group-item border-0 d-flex align-items-center justify-content-between ps-0 mb-2 border-radius-lg pt-0"
        >
          <div class="d-flex align-items-center">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ getAddressText(address) }}
            </h6>
          </div>
          <div
            class="font-weight-bold p-1 text-xs text-white rounded"
            :class="address.status === 'Active' ? 'bg-success ' : 'bg-danger'"
          >
            {{ address.status }}
          </div>

          <div class="d-flex align-items-center text-sm">
            <router-link
              v-if="$can('update', 'addresses')"
              :to="{
                name: 'Edit Client Address',
                params: { id: $route.params.id, addressId: address.id },
              }"
              class="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
            >
              <i class="fas fa-edit text-lg me-1" aria-hidden="true"></i>
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ApiAddresses from "@/services/apiAddresses";
export default {
  name: "ProfileAddressesCard",
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},
  methods: {
    getAddressText(address) {
      return ApiAddresses.getAddressText(address);
    },
  },
};
</script>
