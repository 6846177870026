<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="text-right col-12 d-flex flex-column justify-content-center">
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{
              name: 'Client Profile',
              params: { id: clientId },
            }"
            class="btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Edit address</h5>
            <div
              class="text-right col-lg-12 d-flex flex-column justify-content-center"
            >
              <loading-spinner :loading="loading.addresses" />
              <div v-if="!loading.addresses" class="row">
                <div class="col-12">
                  <client-address
                    v-model:id="clientAddress.id"
                    v-model:city-id="clientAddress.city_id"
                    v-model:area-id="clientAddress.area_id"
                    v-model:type="clientAddress.type"
                    v-model:street="clientAddress.street"
                    v-model:landmark="clientAddress.landmark"
                    v-model:building-name="clientAddress.building_name"
                    v-model:flat-number="clientAddress.flat_number"
                    v-model:latitude="clientAddress.latitude"
                    v-model:longitude="clientAddress.longitude"
                    v-model:notes="clientAddress.notes"
                    v-model:status="clientAddress.status"
                    :active="'active show'"
                    :address-key="clientAddressKey"
                    :area-name="clientAddress['area.name']"
                    :city-name="clientAddress['city.name']"
                    :client-id="clientId"
                    :address-areas-initial="addressAreas"
                    :address-cities="addressCities"
                    :address-types="addressTypes"
                    @update-client-address="updateClientAddress"
                    @delete-client-address="deleteClientAddress"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientAddress from "@/views/pages/Clients/components/Address";
import ApiAddresses from "@/services/apiAddresses";
import { showMessage } from "@/assets/js/show-message";
import { handleError } from "@/lib/helpers";
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: "Edit",
  components: {
    LoadingSpinner,
    ClientAddress,
  },
  data() {
    return {
      clientAddresses: [],
      clientAddress: {},
      clientAddressKey: 0,
      loading: {
        addresses: true,
      },
      formSubmitted: false,
      clientId: parseInt(this.$route.params.id),
      clientAddressId: parseInt(this.$route.params.addressId),
      addressAreas: [],
      addressCities: [],
      addressTypes: [],
    };
  },
  async mounted() {
    this.addressAreas = await ApiAddresses.getAreas("start=0&length=-1");
    this.addressCities = await ApiAddresses.getCities("start=0&length=-1");
    this.addressTypes = ApiAddresses.addressTypeChoices();
    await this.setClientAddresses(this.clientId);
    this.clientAddressKey = this.getAddressKey(this.clientAddressId);
    this.clientAddress = this.clientAddresses[this.clientAddressKey];
    this.loading.addresses = false;
  },
  methods: {
    async setClientAddresses(clientId) {
      let response = await ApiAddresses.get(clientId, "All").catch(handleError);
      if (response.status === 200) {
        this.clientAddresses = response.data.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    getAddressKey(id) {
      let key = this.clientAddresses.findIndex((x) => x.id === id);
      if (key > -1) {
        return key;
      }
      const appInstance = this;
      this.$swal({
        icon: "error",
        title: "Client address not found",
        willClose: () => {
          appInstance.$router.push({
            name: "Client Profile",
            id: appInstance.clientId,
          });
        },
      });
      return 0;
    },
    updateClientAddress() {},
    deleteClientAddress() {
      const appInstance = this;
      showMessage(
        "Success. Client address deleted successfully.",
        { name: "fa-check-circle", class: "text-success" },
        3500,
        () => {
          this.$router.push({
            name: "Client Profile",
            id: appInstance.clientId,
          });
        }
      );
    },
  },
};
</script>
