<template>
  <div class="row">
    <div class="text-right col-12 d-flex flex-column justify-content-center">
      <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
        <router-link
          v-if="$can('create', 'discount_codes')"
          :to="{ name: 'New Discount' }"
          class="btn btn-white text-success"
        >
          New Discount
        </router-link>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="client-name">Client name</label>
          <input
            id="client-name"
            v-model="filter.name"
            type="search"
            class="form-control"
            placeholder="Name"
          />
        </div>
        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="status">Status</label>
          <select
            id="status"
            v-model="filter.status"
            class="form-control"
          ></select>
        </div>

        <div class="col-md-3 mt-3">
          <label class="visually-hidden" for="code">Code</label>
          <input
            id="code"
            v-model="filter.code"
            type="search"
            class="form-control"
            placeholder="Code"
          />
        </div>
        <div class="col-md-3 mt-3">
          <argon-select
            v-if="!loading"
            id="category"
            :model-value="filter.category"
            placeholder="Category"
            :options="{
              choices: categoryOptions,
              searchEnabled: false,
            }"
            @update:model-value="filter.category = $event"
          ></argon-select>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-auto mt-3">Valid Start Between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="checkout-time-after"
            >Valid Start date</label
          >
          <flat-pickr
            id="created-after"
            v-model="filter.valid_from_start_date"
            class="form-control"
            placeholder="Valid start from"
            :config="configs.valid_from_start_date"
            @on-change="onDiscountFromStartDateChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="created-before"
            >Valid Start end date</label
          >
          <flat-pickr
            id="created-before"
            v-model="filter.valid_from_end_date"
            class="form-control"
            placeholder="Valid start end  before"
            :config="configs.valid_from_start_date"
            @on-change="onDiscountFromEndDateChange"
          />
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-auto mt-3">Valid End Between</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="checkout-time-after"
            >Valid End from</label
          >
          <flat-pickr
            id="created-after"
            v-model="filter.valid_until_start_date"
            class="form-control"
            placeholder="Valid end from"
            :config="configs.valid_until_start_date"
            @on-change="onDiscountUntilStartDateChange"
          />
        </div>
        <div class="col-md-auto col-12 mt-3">and</div>
        <div class="col-md-auto col-12 mt-3">
          <label class="visually-hidden" for="created-before"
            >Valid End before</label
          >
          <flat-pickr
            id="created-before"
            v-model="filter.valid_until_end_date"
            class="form-control"
            placeholder="Valid end end  before"
            :config="configs.valid_until_end_date"
            @on-change="onDiscountUntilEndDateChange"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 mt-3">
          <argon-switch
            id="public-access"
            v-model:checked="filter.public_access"
            name="public_access"
            class="inline"
            >Show public codes</argon-switch
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <submit-form-button
            :form-submitted="formSubmitted"
            default-button-text="Filter"
            @click="tableDraw"
          />
          <span class="ms-3 btn btn-success btn-sm mb-0" @click="resetFilter()"
            >Reset</span
          >
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="list-table"
          class="table align-items-center mb-0 hover"
          width="100%"
        >
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Code
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Category
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Valid From
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Valid Until
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Public code
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import apiMiscList from "@/services/apiMiscList";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import API from "@/services/api";
import { initChoices, formatDataToChoicesJs } from "@/assets/js/init-choices";
import { showMessage } from "@/assets/js/show-message";
import { dateFormat } from "@/lib/dateHelper";
import { handleError } from "@/lib/helpers";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
export default {
  name: "ListTable",
  components: {
    flatPickr,
    ArgonSwitch,
    ArgonSelect,
    SubmitFormButton,
  },

  data() {
    return {
      formSubmitted: false,
      loading: true,
      filter: {},
      categoryOptions: [],
      configs: {
        valid_from_start_date: {
          maxDate: null,
          minDate: null,
        },
        valid_from_end_date: {
          maxDate: null,
          minDate: null,
        },
        valid_until_start_date: {
          maxDate: null,
          minDate: null,
        },
        valid_until_end_date: {
          maxDate: null,
          minDate: null,
        },
      },
    };
  },
  async mounted() {
    this.resetFilter();
    await this.initCategoryOptions();
    this.initDiscountStatus();
    this.initDataTable();
    this.loading = false;
  },
  beforeUnmount() {
    $("#list-table").DataTable().destroy();
  },
  methods: {
    resetFilter() {
      this.filter = {
        name: "",
        status: [],
        code: "",
        category: "",
        public_access: false,
        valid_from_start_date: "",
        valid_from_end_date: "",
        valid_until_start_date: "",
        valid_until_end_date: "",
      };
    },
    async initCategoryOptions() {
      const response = await apiMiscList
        .discountCodeCategory()
        .catch(handleError);
      if (response.status === 200 && response.data?.data) {
        this.categoryOptions = [
          { value: "", label: "Category" },
          ...formatDataToChoicesJs(response.data?.data, "", "enum"),
        ];
      }
    },
    initDataTable() {
      const appInstance = this;
      $.fn.dataTable.ext.classes.sWrapper =
        "dataTables_wrapper dataTable-wrapper";
      $.fn.DataTable.ext.pager.numbers_length = 5;
      $("#list-table").DataTable({
        processing: true,
        serverSide: true,
        order: [[0, "desc"]],
        pagingType: "simple_numbers",
        language: {
          paginate: {
            previous: "‹",
            next: "›",
          },
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
            width: "60px",
          },
          {
            data: "name",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "code",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "category",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                data +
                "</h6></div>"
              );
            },
          },
          {
            data: "valid_from",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                dateFormat(data) +
                "</h6></div>"
              );
            },
          },
          {
            data: "valid_until",
            render: function (data) {
              return (
                '<div class="d-flex flex-column justify-content-center"><h6 class="mb-0 text-sm">' +
                dateFormat(data) +
                "</h6></div>"
              );
            },
          },
          {
            data: "status",
            render: function (data) {
              return (
                '<span class="badge badge-dot me-4"><i class="' +
                (data == "Active" ? "bg-success" : "bg-danger") +
                '"></i><span class="text-dark text-xs">' +
                data +
                "</span></span>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: "public_access",
            render: function (data) {
              return (
                '<span class="badge badge-dot me-4"><i class="' +
                (data == "1" ? "bg-success" : "bg-danger") +
                '"></i><span class="text-dark text-xs">' +
                (data == "1" ? "Public" : "Private") +
                "</span></span>"
              );
            },
            className: "align-middle text-center text-sm",
          },
          {
            data: null,
            render: function (data) {
              let button = "a";
              if (appInstance.$ability.can("update", "discount_codes")) {
                button =
                  '<div class="d-flex flex-column justify-content-center">' +
                  '<h6 class="mb-0 text-sm">' +
                  '<a href="' +
                  appInstance.$router.resolve({
                    name: "Edit Discount",
                    params: {
                      id: data.id,
                    },
                  }).href +
                  '" class="mx-3"><i class="far fa-edit text-secondary"></i></a>' +
                  "</h6>" +
                  "</div>";
              }

              return button;
            },
            className: "align-middle text-center text-sm",
          },
        ],
        ajax: function (data, callback) {
          appInstance.formSubmitted = true;
          const requestFilter = { ...appInstance.filter };
          requestFilter.public_access = requestFilter.public_access
            ? 1
            : undefined;
          data.filter = requestFilter;
          API.discountCodetList($.param(data))
            .then((res) => {
              callback(res.data);
              appInstance.formSubmitted = false;
            })
            .catch((err) => {
              const response = API.handleError(err);
              showMessage(response.message, "error");
              appInstance.formSubmitted = false;
            });
        },
      });
    },
    tableDraw() {
      $("#list-table").DataTable().draw();
    },
    onDiscountFromStartDateChange(selectedDates, dateStr) {
      this.configs.valid_from_end_date.minDate = dateStr;
    },
    onDiscountFromEndDateChange(selectedDates, dateStr) {
      this.configs.valid_from_start_date.maxDate = dateStr;
    },
    onDiscountUntilStartDateChange(selectedDates, dateStr) {
      this.configs.valid_until_end_date.minDate = dateStr;
    },
    onDiscountUntilEndDateChange(selectedDates, dateStr) {
      this.configs.valid_until_start_date.maxDate = dateStr;
    },
    initDiscountStatus() {
      const id = "status";
      initChoices(
        id,
        {
          choices: apiMiscList.getDiscountCodeStatus("Status"),
          searchEnabled: false,
          duplicateItemsAllowed: false,
        },
        "",
        "Select Status"
      );
    },
  },
};
</script>
