<template>
  <bar-chart
    v-if="chartData"
    id="distance-covered"
    :key="chartData.labels.join(',')"
    :legend="true"
    :chart="chartData"
    class="h-100"
    :loading="loading"
  >
    <HealthHeader
      :selected-date="startDate"
      title="Distance"
      @update-graph="updateGraph"
    />
    <HealthFilter
      :interval="interval"
      :loading="loading"
      @filter-by="updateGraph"
    />
    <HealthNavigator
      :start-date="startDate"
      :end-date="endDate"
      :today="today"
      :interval="interval"
      :loading="loading"
      @update-graph="updateGraph"
    />
    <template v-if="total_distance" #displayData
      ><ResultDisplay v-if="total_distance" title="Average Temperature">
        <span class="">{{ total_distance }}km </span>
      </ResultDisplay>
    </template>
  </bar-chart>
</template>
<script>
import BarChart from "../components/BarChart.vue";
import apiHealth from "@/services/apiHealth";
import HealthFilter from "./components/Filter.vue";
import ResultDisplay from "./components/ResultDisplay.vue";
import { dateFormat } from "@/lib/dateHelper";
import HealthNavigator from "./components/HealthNavigator.vue";
import HealthHeader from "./components/HealthHeader.vue";
export default {
  name: "DistanceCover",
  components: {
    BarChart,
    HealthFilter,
    ResultDisplay,
    HealthNavigator,
    HealthHeader,
  },
  data() {
    return {
      interval: "day",
      chartData: "",
      total_distance: "",
      today: dateFormat("today", "YYYY-MM-DD"),
      startDate: "",
      endDate: "",
      loading: false,
    };
  },
  async mounted() {
    this.fetchBP(this.today, this.today);
  },
  methods: {
    async updateGraph(start, end, interval) {
      this.interval = interval;
      await this.fetchBP(start, end);
    },
    async fetchBP(start, end) {
      this.startDate = start;
      this.endDate = end;
      this.loading = true;
      try {
        const res = await apiHealth.getDistanceCovered(
          this.$route.params.id,
          start,
          end
        );
        if (res && res.data.graphData) {
          const labels = Object.keys(res.data.graphData);
          const displayLabels = labels.map((label) =>
            /^\d{4}-\d{2}$/.test(label)
              ? dateFormat(new Date(label + "-01"), { month: "short" })
              : label
          );
          const temperatureData = labels.map(
            (time) => res.data.graphData[time]
          );

          this.chartData = {
            labels: displayLabels,
            datasets: {
              label: "Distance Covered",
              data: temperatureData,
              backgroundColor: "#42f5e6",
            },
          };
          if (res.data.sum_distance !== 0) {
            this.total_distance = res.data.sum_distance.toFixed(2);
          } else {
            this.total_distance = null;
          }
        }
      } catch (error) {
        console.error("Error fetching distance cover data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
