<template>
  <GradientLineChart
    v-if="chartData"
    id="heart-rate"
    :key="chartData.labels.join(',')"
    :legend="true"
    :y-axis="true"
    :chart="chartData"
    class="h-100"
    :loading="loading"
  >
    <HealthHeader
      :selected-date="startDate"
      title="Heart Rate"
      @update-graph="updateGraph"
    />
    <HealthFilter
      :interval="interval"
      :loading="loading"
      @filter-by="updateGraph"
    />
    <HealthNavigator
      :start-date="startDate"
      :end-date="endDate"
      :today="today"
      :interval="interval"
      :loading="loading"
      @update-graph="updateGraph"
    />
    <template #displayData
      ><ResultDisplay title="Average Heart Rate">
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            <span class="font-bold text-red-500">{{ avg_heart_rate }}bpm </span>
          </div>
        </div>
      </ResultDisplay>
    </template>
  </GradientLineChart>
</template>
<script>
import GradientLineChart from "@/components/GradientLineChart.vue";
import apiHealth from "@/services/apiHealth";
import HealthFilter from "./components/Filter.vue";
import ResultDisplay from "./components/ResultDisplay.vue";
import { dateFormat } from "@/lib/dateHelper";
import HealthNavigator from "./components/HealthNavigator.vue";
import HealthHeader from "./components/HealthHeader.vue";
export default {
  name: "HeartRate",
  components: {
    GradientLineChart,
    HealthFilter,
    ResultDisplay,
    HealthNavigator,
    HealthHeader,
  },
  data() {
    return {
      interval: "day",
      chartData: "",
      avg_heart_rate: "",
      today: dateFormat("today", "YYYY-MM-DD"),
      startDate: "",
      endDate: "",
      loading: false,
    };
  },
  async mounted() {
    await this.fetchBP(this.today, this.today);
  },
  methods: {
    async updateGraph(start, end, interval) {
      this.interval = interval;
      await this.fetchBP(start, end);
    },
    async fetchBP(start, end) {
      this.startDate = start;
      this.endDate = end;
      this.loading = true;
      try {
        const res = await apiHealth.getHeartRate(
          this.$route.params.id,
          start,
          end
        );
        if (res && res.data.graphData) {
          const labels = Object.keys(res.data.graphData);
          const displayLabels = labels.map((label) =>
            /^\d{4}-\d{2}$/.test(label)
              ? dateFormat(new Date(label + "-01"), { month: "short" })
              : label
          );
          const hearRate = labels.map((time) => res.data.graphData[time]);

          this.chartData = {
            labels: displayLabels,
            datasets: [
              {
                label: "Heart Rate",
                data: hearRate,
                borderColor: "#FF3B30",
                backgroundColor:
                  "rgba(255,59,48,0.4)                                               ",
              },
            ],
          };
          if (res.data.average_heart_rate !== null) {
            this.avg_heart_rate = res.data.average_heart_rate.toFixed(2);
          } else {
            this.avg_heart_rate = 0;
          }
        }
      } catch (error) {
        console.error("Error fetching heart rate data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
