<template>
  <main>
    <loading-spinner :loading="loading" />
    <div v-show="!loading" class="py-4 container-fluid">
      <div class="row">
        <div
          class="text-right col-lg-12 d-flex flex-column justify-content-center"
        >
          <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
            <button
              type="button"
              class="btn btn-white text-success"
              @click="saveDiscount"
            >
              Save discount
            </button>
            <button
              type="button"
              class="btn btn-white text-success ms-3"
              @click="saveDiscount('duplicate')"
            >
              Duplicate discount
            </button>
            <router-link
              :to="{ name: 'Discounts' }"
              class="ms-3 btn btn-white text-success"
            >
              Back
            </router-link>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="mt-4 col-lg-12 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Discount Information</h5>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <label>Name<sup>*</sup></label>
                  <input
                    id="discount-name"
                    v-model="discountForm.name"
                    class="form-control"
                    type="text"
                    placeholder="name"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label>Code<sup>*</sup></label>
                  <input
                    id="discount-code"
                    v-model="discountForm.code"
                    class="form-control"
                    type="text"
                    placeholder="code"
                  />
                </div>
              </div>

              <div class="row">
                <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                  <label class="mt-4">Category<sup>*</sup></label>
                  <select
                    id="discount-category"
                    v-model="discountForm.category"
                    class="form-control"
                    placeholder="Category"
                  ></select>
                </div>
                <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                  <label class="mt-4">Type<sup>*</sup></label>
                  <select
                    id="discount-type"
                    v-model="discountForm.type"
                    class="form-control"
                  ></select>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-4">
                  <label class="mt-4">Valid From</label>
                  <flat-pickr
                    id="discount-created-after"
                    v-model="discountForm.valid_from"
                    class="form-control"
                    placeholder="Valid From"
                    :config="configs.valid_from"
                    @on-change="onValidFromChange"
                  />
                </div>
                <div class="col-12 col-sm-4">
                  <label class="mt-4"
                    >Valid Until (<span class="text-xs text-danger"
                      >not included</span
                    >)</label
                  >
                  <flat-pickr
                    id="discount-created-before"
                    v-model="discountForm.valid_until"
                    class="form-control"
                    placeholder="Valid Until"
                    :config="configs.valid_until"
                    @on-change="onValidUntilChange"
                  />
                </div>
                <div class="col-12 col-sm-4">
                  <div class="row">
                    <div class="col-6">
                      <label class="mt-4">Status</label>
                      <argon-switch
                        id="discountForm-status-switch"
                        v-model:checked="discountForm.status"
                        name="discount_status"
                        >Active</argon-switch
                      >
                    </div>
                    <div class="col-6">
                      <label class="mt-4">Is public</label>
                      <argon-switch
                        id="discountForm-public_access-switch"
                        v-model:checked="discountForm.public_access"
                        name="public_access"
                        >Active</argon-switch
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <label class="mt-4">Partner Page</label>
                  <input
                    id="discount-partner-page"
                    v-model="discountForm.partner_page"
                    class="form-control"
                    type="text"
                    placeholder="Partner page"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="mt-4">Gift Card</label>
                  <input
                    id="discount-gift-card"
                    v-model="discountForm.gift_card"
                    class="form-control"
                    type="text"
                    placeholder="Gift card"
                  />
                </div>
              </div>

              <div class="row">
                <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                  <label class="mt-4">User</label>
                  <select
                    id="discount-user-id"
                    v-model="discountForm.user_id"
                    class="form-control"
                    placeholder="User"
                  ></select>
                </div>
                <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                  <label class="mt-4">Allowed User Ids</label>
                  <select
                    id="allowed_user_ids"
                    v-model="discountForm.allowed_user_ids"
                    multiple
                    class="form-control"
                  ></select>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <label class="mt-4">Instruction</label>
                  <textarea
                    id="discountForm-instruction"
                    v-model="discountForm.instruction"
                    class="form-control"
                    rows="5"
                  >
                  </textarea>
                </div>
                <div class="col-12 col-sm-6">
                  <label class="mt-4">Success message</label>
                  <textarea
                    id="success_message"
                    v-model="discountForm.success_message"
                    class="form-control"
                    rows="2"
                  >
                  </textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <label class="mt-4">coupon Usage Limit</label>
                  <input
                    id="coupon_usage_limit"
                    v-model="discountForm.coupon_usage_limit"
                    class="form-control"
                    type="text"
                    placeholder="coupon Usage Limit"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="mt-4">Coupon usage per user limit</label>
                  <input
                    id="coupon_usage_per_user_limit"
                    v-model="discountForm.coupon_usage_per_user_limit"
                    :checked="discountForm.coupon_usage_per_user_limit"
                    class="form-control"
                    type="text"
                    placeholder="coupon Usage Limit"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="mt-4">Invoice Text<sup>*</sup></label>
                  <input
                    id="invoice_text"
                    v-model="discountForm.invoice_text"
                    class="form-control"
                    type="text"
                    placeholder="Invoice Text"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-4 col-sm-4 mt-4">
                  <div class="form-check my-auto ms-auto">
                    <input
                      id="auto_apply"
                      v-model="discountForm.auto_apply"
                      class="form-check-input"
                      type="checkbox"
                    />
                    <label>Auto apply</label>
                  </div>

                  <div class="form-check my-auto ms-auto">
                    <input
                      id="use_with_other_promo"
                      v-model="discountForm.use_with_other_promo"
                      class="form-check-input"
                      type="checkbox"
                    />
                    <label>Use with other promo</label>
                  </div>
                </div>
                <div class="col-4 col-sm-4">
                  <checkbox-inputs
                    v-if="validDaysPerWeekChoices.length > 0"
                    v-model="discountForm.valid_days_per_week"
                    class="col-4 col-sm-3"
                    title="Valid days per week"
                    :options="validDaysPerWeekChoices"
                    name="valid_days_per_week"
                  />
                </div>
                <div class="col-4 col-sm-4">
                  <checkbox-inputs
                    v-if="validWeeksChoices.length > 0"
                    v-model="discountForm.valid_weeks"
                    class="col-4 col-sm-3"
                    title="Valid weeks"
                    :options="validWeeksChoices"
                    name="valid_weeks"
                  />
                </div>
              </div>
              <div class="row">
                <checkbox-inputs
                  v-if="sourceChoices.length > 0"
                  v-model="discountForm.sources"
                  class="col-4 col-sm-3"
                  title="Sources"
                  :options="sourceChoices"
                  name="source"
                />
                <checkbox-inputs
                  v-if="purchaseTypeChoices.length > 0"
                  v-model="discountForm.purchase_type"
                  class="col-4 col-sm-3"
                  title="Purchase Type"
                  :options="purchaseTypeChoices"
                  name="purchase_type"
                />
                <checkbox-inputs
                  v-if="validForChoices.length > 0"
                  v-model="discountForm.valid_for"
                  class="col-4 col-sm-3"
                  title="Valid For"
                  :options="validForChoices"
                  name="valid_for"
                />
                <checkbox-inputs
                  v-if="durationChoices.length > 0"
                  v-model="discountForm.duration"
                  class="col-4 col-sm-3"
                  title="Duration"
                  :options="durationChoices"
                  name="duration"
                />
              </div>
              <div class="row">
                <div class="col-12 col-sm-12">
                  <label class="mt-4" @click="toggleShowPlanVariants"
                    >Valid Plan Variants <span>(show/hide)</span></label
                  >
                  <div v-show="showPlanVariants" class="row">
                    <div
                      v-for="select in [
                        { value: 'all', title: 'All plans' },
                        {
                          value: 'except_pm_am',
                          title: 'All plans (except am, pm)',
                        },
                        { value: 'am_plans', title: 'All AM plans' },
                        { value: 'pm_plans', title: 'All PM plans' },
                        { value: 'none', title: 'None' },
                      ]"
                      :key="select.value"
                      class="col-sm-2"
                    >
                      <div
                        class="card border highlight-border"
                        @click="selectPlanVariant(select.value)"
                      >
                        <div
                          class="text-center card-body d-flex flex-column justify-content-center sm-6"
                        >
                          <label>{{ select.title }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="showPlanVariants" class="row">
                    <div class="col-8">
                      <label class="mt-3"
                        >Filter plan variants
                        <span @click="toggleShowFilterPlans">
                          (show/hide)</span
                        ></label
                      >
                      <input
                        v-show="showFilterPlans"
                        v-model="searchTerm"
                        class="form-control"
                        type="search"
                        placeholder="Search plans"
                      />
                    </div>
                    <div v-show="showFilterPlans" class="col-4">
                      <label class="mt-3">&nbsp;</label>
                      <div class="dropdown">
                        <a
                          id="dropdownMenuButton"
                          href="#"
                          class="p-0"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <strong>Filter presets</strong>
                        </a>
                        <ul
                          class="px-2 py-3 dropdown-menu dropdown-menu-start me-sm-n4 mt-0 dropdown-user-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li class="mb-2">
                            <a
                              v-for="(term, index) in searchSets"
                              :key="index"
                              class="dropdown-item border-radius-md"
                              @click="addToSearchTerm(term, true)"
                            >
                              {{ term }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      v-show="showFilterPlans"
                      class="text-muted text-xs mb-3 align-items-center col-12"
                    >
                      <span>keywords:</span>
                      <button
                        v-for="(term, index) in searchKeywords"
                        :key="index"
                        class="btn btn-xs ms-2 mb-0"
                        @click="addToSearchTerm(term)"
                      >
                        {{ term }}
                      </button>
                    </div>
                  </div>
                  <div v-show="showPlanVariants" class="row">
                    <div class="col-12 col-sm-12">
                      <div class="row">
                        <div
                          v-for="(plan_variant, index) in filteredPlanVariants"
                          :key="index"
                          class="col-sm-2 mt-2"
                        >
                          <div class="card border" style="height: 100%">
                            <div
                              class="text-center card-body justify-content-center sm-6"
                            >
                              <div
                                class="form-check"
                                style="
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                  padding-left: 0;
                                "
                              >
                                <input
                                  :id="'plan-variant-' + plan_variant.id"
                                  v-model="discountForm.valid_plan_variants"
                                  :value="plan_variant.id"
                                  class="form-check-input"
                                  type="checkbox"
                                  style="
                                    border: 1px solid #cbd3da;
                                    margin-left: 0;
                                  "
                                />
                                <label
                                  :for="`plan-variant-` + plan_variant.id"
                                  class="mt-1"
                                  >{{ plan_variant.name }}</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12">
                  <label class="mt-4 col-12">Value Added Service</label>
                  <div class="mt-4 row">
                    <div
                      v-for="(service, index) in services"
                      :key="index"
                      class="col-sm-2 mt-2"
                    >
                      <div class="card border">
                        <div
                          class="text-center card-body justify-content-center sm-6"
                        >
                          <div
                            class="form-check"
                            style="
                              display: flex;
                              flex-direction: column;
                              align-items: center;
                              padding-left: 0;
                            "
                          >
                            <input
                              :id="'value-added-service-' + service.id"
                              v-model="discountForm.value_added_service_ids"
                              :value="service.id"
                              class="form-check-input"
                              type="checkbox"
                              style="border: 1px solid #cbd3da; margin-left: 0"
                            />
                            <label
                              :for="'value-added-service' + service.id"
                              class="mt-1"
                              >{{ service.name }}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="form-check my-auto ms-auto" v-for="(service, index) in services" :key="index">
                    <input :value="service.id" v-model="discountForm.value_added_service_ids"
                      :id="'plan-variant-' + service.id" class="form-check-input" type="checkbox" />
                    <label>{{ service.name }}</label>
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header pb-0 p-3">
              <h6 class="mb-0">Discount By Week</h6>
            </div>
            <div class="card-body p-3">
              <div
                v-for="(item, index) in discountForm.discounts_by_week"
                :key="index"
                class="row mt-2"
              >
                <div class="col-sm-3 col-12 my-auto">
                  <div class="form-check my-auto ms-auto">
                    <input
                      :id="'discount-by-week-' + index"
                      :value="index"
                      class="form-check-input"
                      type="checkbox"
                      :checked="true"
                    />
                    <label>{{ index }} weeks</label>
                  </div>
                </div>
                <div class="col-sm-3 col-12 my-auto">
                  <div class="">
                    <input
                      :id="'discount-by-week-value-' + index"
                      v-model="discountForm.discounts_by_week[index]['value']"
                      class="form-control"
                      type="text"
                      placeholder="Discount Value"
                      :readonly="
                        item['percentage'] != null && item['percentage'] != ''
                      "
                    />
                  </div>
                </div>
                <div class="col-sm-3 col-12 my-auto">
                  <div class="">
                    <input
                      :id="'discount-by-week-percent-' + index"
                      v-model="
                        discountForm.discounts_by_week[index]['percentage']
                      "
                      class="form-control"
                      type="text"
                      placeholder="Discount Percent"
                      :readonly="item['value'] != null && item['value'] != ''"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="text-right col-lg-12 d-flex flex-column justify-content-center"
        >
          <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
            <button
              type="button"
              class="btn mb-0 bg-gradient-success btn-sm"
              @click="saveDiscount"
            >
              Save discount
            </button>
            <button
              type="button"
              class="btn mb-0 bg-gradient-success btn-sm ms-3"
              @click="saveDiscount('duplicate')"
            >
              Duplicate discount
            </button>
            <router-link :to="{ name: 'Discounts' }" class="ms-3">
              <argon-button
                class="btn mb-0 bg-gradient-success btn-sm"
                color="success"
                variant="gradient"
                size="sm"
                >Back
              </argon-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import API from "@/services/api";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { showMessage } from "@/assets/js/show-message";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import LoadingSpinner from "@/components/LoadingSpinner";
import ApiDiscounts from "@/services/apiDiscounts";
import { dateFormat } from "@/lib/dateHelper";
import { handleError } from "@/lib/helpers";
import CheckboxInputs from "@/components/CheckboxInputs.vue";
import apiMiscList from "@/services/apiMiscList";
import { mapState, mapActions } from "vuex";

export default {
  name: "Edit",
  components: {
    LoadingSpinner,
    ArgonButton,
    ArgonSwitch,
    flatPickr,
    CheckboxInputs,
  },
  data() {
    return {
      discountForm: {
        id: this.$route.params.id || 0,
        name: "",
        code: "",
        category: "",
        duration: [],
        instruction: "",
        valid_from: dateFormat(new Date(), "YYYY-MM-DD"),
        valid_until: dateFormat(new Date(), "YYYY-MM-DD"),
        valid_days_per_week: [],
        valid_weeks: [],
        valid_plan_variants: [],
        success_message: "",
        discounts_by_week: {},
        coupon_usage_limit: 1,
        coupon_usage_per_user_limit: 1,
        auto_apply: false,
        use_with_other_promo: false,
        partner_page: "",
        gift_card: "",
        type: "",
        user_id: "",
        valid_for: [],
        allowed_user_ids: [],
        value_added_service_ids: [],
        sources: [],
        invoice_text: "",
      },
      loading: true,
      formSubmitted: false,
      choices: {},
      plan_variants: [],
      users: [],
      services: [],
      filter_plan: "",
      plan_variants_loop: [],
      sourceChoices: [],
      validDaysPerWeekChoices: [],
      validWeeksChoices: [],
      validForChoices: [],
      durationChoices: [],
      purchaseTypeChoices: [],
      configs: {
        valid_from: {
          maxDate: null,
        },
        valid_until: {
          minDate: null,
        },
      },
      searchTerm: "",
      searchSets: [
        "!chef && !wellness && !arabic  && !athletes && !vegan && !hydration && !atyab && !new mums && !detox && !starter && !trial && !6 && !7 && && !upsize &&!am &&!pm",
        "international || diabetic || pescatarian || vegetarian || gluten-free",
        "!am && !pm",
      ],
      searchKeywords: [
        "international",
        "arabic",
        "diabetic",
        "pescatarian",
        "vegetarian",
        "gluten-free",
        "wellness",
        "new mums",
        "athletes",
        "am",
        "pm",
        "upsize",
        "success",
        "trial",
        "5",
        "6",
        "7",
        "set-to-go",
        "starter",
        "detox",
        "||",
        "&&",
        "(",
        ")",
        "!",
      ],
    };
  },
  computed: {
    ...mapState("settings", {
      showPlanVariants: (state) => state.discounts.showPlanVariants,
      showFilterPlans: (state) => state.discounts.showFilterPlans,
    }),
    filteredPlanVariants() {
      if (!this.searchTerm.trim()) {
        return this.plan_variants_loop;
      }

      const evaluateExpression = (query, planName) => {
        planName = planName.toLowerCase();

        const withoutParens = query.replace(/\((.*?)\)/g, (_, group) => {
          return evaluateGroup(group, planName);
        });

        return evaluateGroup(withoutParens, planName);
      };

      const evaluateGroup = (expression, planName) => {
        const orParts = expression.split("||").map((part) => part.trim());

        return orParts.some((orPart) => {
          const andParts = orPart.split("&&").map((part) => part.trim());

          return andParts.every((condition) => {
            if (condition === "true") return true;
            if (condition === "false") return false;

            if (condition.startsWith("!")) {
              const term = condition.slice(1).trim();
              return !planName.includes(term);
            }

            return planName.includes(condition);
          });
        });
      };

      return this.plan_variants_loop.filter((plan) => {
        const planName = plan.name.toLowerCase();
        return evaluateExpression(this.searchTerm.toLowerCase(), planName);
      });
    },
  },

  async mounted() {
    this.loading = true;
    this.sourceChoices = API.getSourceChoices();
    this.validDaysPerWeekChoices = formatDataToChoicesJs(
      apiMiscList.validDaysPerWeek()
    );
    this.validWeeksChoices = formatDataToChoicesJs(apiMiscList.validWeeks());
    this.validForChoices = formatDataToChoicesJs(apiMiscList.validFor());
    this.durationChoices = formatDataToChoicesJs(apiMiscList.duration());
    this.purchaseTypeChoices = formatDataToChoicesJs(
      (await apiMiscList.purchaseType()).data.data,
      [],
      "enum"
    );
    this.plan_variants = await API.getAllPlanVariants();
    this.plan_variants = this.plan_variants.data.data;
    this.plan_variants_loop = this.plan_variants;
    this.users = await API.getUsers("length=-1&start=0");
    this.services = await API.getValueAddedServices();
    this.services = this.services.data.data;
    await this.getDiscountDetail();
    this.addMissingDiscountsByWeek();
    this.loading = false;
    await this.initDiscountTypeChoices();
    await this.initCategoryChoices();
    await this.loadUsersChoices();
  },

  methods: {
    dateFormat,
    async initDiscountTypeChoices() {
      await initChoices(
        "discount-type",
        { choices: ApiDiscounts.getDiscountTypeChoices() },
        this.discountForm.type
      );
    },
    async initCategoryChoices() {
      const choices = formatDataToChoicesJs(
        (await apiMiscList.discountCodeCategory()).data.data,
        [],
        "enum"
      );
      await initChoices(
        "discount-category",
        {
          choices: choices,
        },
        this.discountForm.category
      );
    },
    async saveDiscount(action = "save") {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = {};

      for (let key in this.discountForm) {
        if (key == "discounts_by_week") {
          continue;
        }

        formData[key] = this.discountForm[key] ? this.discountForm[key] : "";
      }
      formData["discounts_by_week"] = JSON.stringify(
        this.discountForm.discounts_by_week
      );
      formData["auto_apply"] = this.discountForm.auto_apply ? "1" : "0";
      formData["status"] =
        this.discountForm.status === true ? "Active" : "Inactive";
      formData["public_access"] =
        this.discountForm.public_access === true ? "1" : "0";
      formData["use_with_other_promo"] = this.discountForm.use_with_other_promo
        ? "1"
        : "0";
      let saveDiscountResponse;
      let message;
      if (action === "duplicate" || this.discountForm.id == 0) {
        if (action === "duplicate") {
          formData["name"] += " Duplicate " + dateFormat("today", "YYYY-MM-DD");
          formData["code"] += " Duplicate " + dateFormat("today", "YYYY-MM-DD");
          formData["valid_from"] = dateFormat("today", "YYYY-MM-DD");
          formData["valid_until"] = dateFormat("today", "YYYY-MM-DD");
        }
        saveDiscountResponse = await API.createDiscount(formData).catch(
          handleError
        );
        message = "Discount created successfully.";
      } else {
        formData["_method"] = "PATCH";
        saveDiscountResponse = await API.updateDiscount(
          this.discountForm.id,
          formData
        ).catch(handleError);
        message = "Discount updated successfully.";
      }

      if (saveDiscountResponse.status == 200) {
        this.discountForm.id = saveDiscountResponse.data.data.id;
        showMessage(message, "success");
        if (action === "duplicate") {
          await this.getDiscountDetail();
        }
        this.$router.push({
          name: "Edit Discount",
          params: { id: this.discountForm.id },
        });
      } else {
        showMessage(saveDiscountResponse.message, "error");
      }

      this.formSubmitted = false;
    },
    async loadUsersChoices() {
      const choices = formatDataToChoicesJs(this.users.data.data, "", {
        value: "id",
        label: "name",
        id: "id",
      });
      await initChoices(
        "discount-user-id",
        { choices: choices },
        this.discountForm.user_id
      );
      await initChoices(
        "allowed_user_ids",
        { choices: choices },
        this.discountForm.allowed_user_ids
      );
    },

    getDiscountsByWeek() {
      return {
        1: {
          value: "",
          percentage: "",
        },
        2: {
          value: "",
          percentage: "",
        },
        4: {
          value: "",
          percentage: "",
        },
        8: {
          value: "",
          percentage: "",
        },
        12: {
          value: "",
          percentage: "",
        },
      };
    },
    async getDiscountDetail() {
      if (this.$route.path.includes("new")) {
        return;
      }
      await API.getDiscountCodetDetail(this.discountForm.id)
        .then((res) => {
          const data = res.data.data;
          const {
            id,
            name,
            code,
            category,
            duration,
            instruction,
            valid_days_per_week,
            valid_weeks,
            valid_plan_variants,
            value_added_service_ids,
            sources,
            purchase_type,
            success_message,
            type,
            valid_from,
            valid_until,
            coupon_usage_limit,
            coupon_usage_per_user_limit,
            auto_apply,
            invoice_text,
            use_with_other_promo,
            partner_page,
            gift_card,
            valid_for,
            user_id,
            status,
            allowed_user_ids,
            discounts_by_week,
            public_access,
          } = data;

          this.discountForm = {
            id,
            name,
            code,
            category,
            duration: !duration || duration[0] == null ? [] : duration,
            instruction,
            valid_days_per_week,
            valid_weeks,
            valid_plan_variants,
            value_added_service_ids:
              !value_added_service_ids || value_added_service_ids[0] === null
                ? []
                : value_added_service_ids,
            sources: sources === null || sources[0] === null ? [] : sources,
            purchase_type:
              purchase_type === null || purchase_type[0] === null
                ? []
                : purchase_type,
            success_message,
            type,
            valid_from: dateFormat(valid_from, "YYYY-MM-DD"),
            valid_until: dateFormat(valid_until, "YYYY-MM-DD"),
            coupon_usage_limit,
            coupon_usage_per_user_limit,
            auto_apply: auto_apply == 1,
            invoice_text,
            use_with_other_promo: use_with_other_promo == 1,
            partner_page: partner_page != "" ? JSON.parse(partner_page) : "",
            gift_card: gift_card ? JSON.parse(gift_card) : "",
            valid_for: valid_for ? valid_for : [],
            user_id: user_id ? user_id : "",
            status: status == "Inactive" ? false : true,
            allowed_user_ids:
              !allowed_user_ids ||
              (allowed_user_ids.length == 1 && allowed_user_ids[0] == null)
                ? []
                : allowed_user_ids,
            discounts_by_week,
            public_access: public_access == 1,
          };
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.status === 404
          ) {
            this.$swal({
              icon: "error",
              title: "Discount not found",
              willClose: () => {
                this.$router.push({ name: "Discounts" });
              },
            });
          } else {
            const response = handleError(err);
            if (response.message) {
              showMessage(response.message, "error");
            }
          }
        });
    },
    addMissingDiscountsByWeek() {
      const requiredWeeks = [1, 2, 4, 8, 12];
      requiredWeeks.forEach((week) => {
        const weekStr = week.toString();
        if (!(weekStr in this.discountForm.discounts_by_week)) {
          this.discountForm.discounts_by_week[weekStr] = {
            value: 0,
            percentage: 0,
          };
        }
      });
    },

    onValidFromChange(selectedDates, dateStr) {
      this.configs.valid_until.minDate = dateStr;
    },
    onValidUntilChange(selectedDates, dateStr) {
      this.configs.valid_from.maxDate = dateStr;
    },
    addToSearchTerm(term, clear = false) {
      if (clear) {
        this.searchTerm = term;
        return;
      }
      this.searchTerm +=
        (this.searchTerm.trim() && !this.searchTerm.trim().endsWith("!")
          ? " "
          : "") + term;
    },
    ...mapActions("settings", ["updateSetting"]),
    toggleShowPlanVariants() {
      this.updateSetting({
        path: "discounts.showPlanVariants",
        value: !this.showPlanVariants,
      });
    },
    toggleShowFilterPlans() {
      this.updateSetting({
        path: "discounts.showFilterPlans",
        value: !this.showFilterPlans,
      });
    },
    selectPlanVariant(value) {
      const currentSelection = new Set(this.discountForm.valid_plan_variants);

      switch (value) {
        case "all":
          this.filteredPlanVariants.forEach((plan) =>
            currentSelection.add(plan.id)
          );
          break;

        case "except_pm_am":
          this.filteredPlanVariants
            .filter((plan) => plan.package_type === "Full")
            .forEach((plan) => currentSelection.add(plan.id));
          break;

        case "am_plans":
          this.filteredPlanVariants
            .filter((plan) => plan.package_type === "AM")
            .forEach((plan) => currentSelection.add(plan.id));
          break;

        case "pm_plans":
          this.filteredPlanVariants
            .filter((plan) => plan.package_type === "PM")
            .forEach((plan) => currentSelection.add(plan.id));
          break;

        case "none":
          this.filteredPlanVariants.forEach((plan) =>
            currentSelection.delete(plan.id)
          );
          break;
      }

      this.discountForm.valid_plan_variants = Array.from(currentSelection);
    },
  },
};
</script>
