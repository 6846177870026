<template>
  <div class="card z-index-2">
    <div class="pb-0 card-header mb-0">
      <h6 v-if="title">{{ title }}</h6>

      <slot />
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
    <slot name="displayData" />
    <LoadingSpinner
      :loading="loading"
      size="16px"
      class="position-absolute top-50 start-50 translate-middle"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Chart from "chart.js/auto";
export default {
  name: "BarChart",
  components: {
    LoadingSpinner,
  },
  props: {
    id: {
      type: String,
      default: "bar-chart",
    },
    title: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "300px",
    },
    legend: {
      type: Boolean,
      default: false,
    },
    yAxis: {
      type: Boolean,
      default: true,
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Object,
        required: true,
        label: String,
        data: Array,
      },
    },
    loading: {
      type: Boolean,
      defaulft: false,
    },
  },
  watch: {
    chart: {
      deep: true,
      handler() {
        this.initChart();
      },
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      var ctx = document.getElementById(this.id).getContext("2d");

      let chartStatus = Chart.getChart(this.id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.chart.labels,
          datasets: [
            {
              label: this.chart.datasets.label,
              weight: 5,
              borderWidth: 0,
              borderRadius: 4,
              backgroundColor: this.chart.datasets.backgroundColor
                ? this.chart.datasets.backgroundColor
                : "#3A416F",
              data: this.chart.datasets.data,
              fill: false,
              maxBarThickness: 35,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: this.legend,
            },
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: this.yAxis,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      });
    },
  },
};
</script>
