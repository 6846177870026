<template>
  <div class="form-check">
    <input
      :id="id"
      class="form-check-input"
      type="checkbox"
      :name="name"
      :value="value"
      :checked="modelValue === value"
      @change="handleChange"
    />
    <label :for="id" class="custom-control-label" :class="$attrs.class">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "ArgonCheckbox",
  props: {
    modelValue: {
      type: [String, Number, Boolean, undefined],
      default: undefined,
    },
    value: {
      type: [String, Number],
      default: "1",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  methods: {
    handleChange(event) {
      this.$emit(
        "update:modelValue",
        event.target.checked ? this.value : undefined
      );
    },
  },
};
</script>
