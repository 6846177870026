<template>
  <loading-spinner :loading="loading" />
  <div v-show="!loading" id="profile" class="card mt-4">
    <div class="card-body">
      <form @submit.prevent="updateClientProfile">
        <div class="row justify-content-center align-items-center">
          <div class="col-12">
            <argon-avatar
              :img="client.profile_picture"
              alt="profile"
              size="xl"
              shadow="sm"
              border-radius="lg"
              class="cursor-pointer"
              @click="showProfilePicturePicker"
            />
            <input
              ref="file"
              type="file"
              style="display: none"
              accept="image/png, image/gif, image/jpeg"
              @change="selectProfilePicture"
            />
          </div>
          <div class="col-12 my-auto">
            <div class="h-100">
              <h5 class="mb-1 font-weight-bolder">{{ clientName }}</h5>
              <p class="mb-0 font-weight-bold text-sm"></p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label class="form-label">Weight</label>
            <argon-input
              id="weight"
              type="text"
              placeholder="Weight"
              :model-value="clientProfile.weight"
              :errors="v$.clientProfile.weight.$errors"
              @update:model-value="clientProfile.weight = $event"
            />
          </div>
          <div class="col-3">
            <label class="form-label">Height</label>
            <argon-input
              id="height"
              type="text"
              placeholder="Height"
              :model-value="clientProfile.height"
              :errors="v$.clientProfile.height.$errors"
              @update:model-value="clientProfile.height = $event"
            />
          </div>
          <div class="col-6">
            <label class="form-label">Goal</label>
            <div class="form-group">
              <select
                id="goal"
                v-model="clientProfile.goal"
                class="form-control"
                name="goal"
              ></select>
              <error-display :errors="v$.clientProfile.goal.$errors" />
            </div>
          </div>
        </div>
        <argon-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          >Update
        </argon-button>
      </form>
    </div>
  </div>
  <div v-show="!loading" id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="updateClientBasicInfo">
        <div class="row">
          <div class="col-6">
            <label class="form-label">First name</label>
            <argon-input
              id="first-name"
              type="text"
              placeholder="First name"
              :model-value="client.first_name"
              :errors="v$.client.first_name.$errors"
              @update:model-value="client.first_name = $event"
            />
          </div>
          <div class="col-6">
            <label class="form-label">Last name</label>
            <argon-input
              id="last-name"
              type="text"
              placeholder="Last Name"
              :model-value="client.last_name"
              :errors="v$.client.last_name.$errors"
              @update:model-value="client.last_name = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label mt-2">Email</label>
            <argon-input
              id="email"
              type="email"
              placeholder="example@email.com"
              :model-value="client.email"
              :errors="v$.client.email.$errors"
              @update:model-value="client.email = $event"
            />
          </div>
          <div class="col-6">
            <label class="form-label mt-2">Mobile Number</label>
            <argon-input
              id="mobile_number"
              type="text"
              placeholder="+971551234567"
              :model-value="client.mobile_number"
              :errors="v$.client.mobile_number.$errors"
              @update:model-value="client.mobile_number = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label mt-2">Birth date</label>
            <div class="form-group">
              <flat-pickr
                v-model="client.birth_date"
                class="form-control"
                placeholder="Select date"
                name="birth_date"
              />
            </div>
          </div>
          <div class="col-6">
            <label class="form-label mt-2">Gender</label>
            <div class="form-group">
              <select
                id="gender"
                v-model="client.gender"
                class="form-control"
                name="gender"
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <error-display :errors="v$.client.gender.$errors" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 align-self-center mt-2">
            <argon-textarea
              id="notes"
              placeholder=""
              :model-value="client.notes"
              @update:model-value="client.notes = $event"
              >Public notes
            </argon-textarea>
          </div>
          <div class="col-md-6 align-self-center mt-2">
            <argon-textarea
              id="private_notes"
              placeholder=""
              :model-value="client.private_notes"
              @update:model-value="client.private_notes = $event"
              >Private notes
            </argon-textarea>
          </div>
        </div>
        <argon-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          >Update
        </argon-button>
      </form>
    </div>
  </div>
  <div v-show="!loading" id="referral" class="card mt-4">
    <div class="card-header">
      <h5>Referral</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="updateClientReferral">
        <div class="row">
          <div class="col-6">
            <label class="form-label"
              >Referral code (If referred by other clients)</label
            >
            <argon-input
              id="referral-code"
              type="text"
              placeholder="Referral code"
              :model-value="referralForm.referral_code"
              @update:model-value="referralForm.referral_code = $event"
            />
          </div>
        </div>
        <submit-form-button
          default-button-text="Update"
          :form-submitted="formSubmitted.clientReferral"
          class="float-end mt-6 mb-0"
        />
      </form>
    </div>
  </div>
  <div v-show="!loading" id="client-support-team-group" class="card mt-4">
    <div class="card-header">
      <h5>Client Support Team</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="updateClientSupportTeam">
        <div class="row">
          <div class="col-md-6 align-self-center">
            <label class="form-label">Account manager</label>
            <div class="d-flex">
              <div class="form-group flex-grow-1">
                <select
                  id="client-am"
                  v-model="clientSupportTeam.account_manager_id"
                  class="form-control"
                ></select>
              </div>
              <button
                v-if="
                  isAccountManager &&
                  getCurrentUserId !== clientSupportTeam.account_manager_id
                "
                type="button"
                class="btn btn-xs bg-gradient-dark rounded-0"
                @click="addCurrentUserAsAccountManager"
              >
                Assign me as AM
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label">Nutritionist</label>
            <div class="form-group">
              <select
                id="client-nutritionist"
                v-model="clientSupportTeam.nutritionist_id"
                class="form-control"
              ></select>
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label">Sales owner</label>
            <select
              id="client-sales-owner"
              v-model="clientSupportTeam.sales_owner_id"
              class="form-control"
            ></select>
          </div>
        </div>

        <argon-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          >Update
        </argon-button>
      </form>
    </div>
  </div>
  <loading-spinner v-if="loading && clientAddresses.length === 0" />
  <addresses-card
    v-if="!loading && clientAddresses.length > 0"
    :initial-addresses="clientAddresses"
    :address-areas="addressAreas"
    @client-addresses-changed="handleClientAddressChanged"
  />
  <div v-show="!loading" id="delivery-preferences" class="card mt-4">
    <div class="card-header">
      <h5>Delivery preferences</h5>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Weekdays</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Delivery location</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Schedule</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Delivery</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="element of clientDeliveryPreferences" :key="element.id">
              <td class="ps-1" colspan="4">
                <span class="text-dark d-block text-sm">{{
                  element.day_text
                }}</span>
              </td>
              <td>
                <select
                  :id="'delivery-address-' + element.day"
                  v-model="element.address_id"
                  class="form-control"
                ></select>
              </td>
              <td>
                <select
                  :id="'delivery-area-timing-' + element.day"
                  v-model="element.area_timing_id"
                  class="form-control"
                ></select>
              </td>
              <td>
                <argon-switch
                  :id="'delivery-' + element.day"
                  v-model:checked="element.delivery"
                  name="Active"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3 row align-items-center">
        <div class="col-auto">
          <label class="form-label mb-0">Cutlery count</label>
        </div>
        <div class="col-auto">
          <select
            id="client-cutlery-count"
            v-model="clientCutleryCount"
            class="form-control"
          >
            <option v-for="index in 8" :key="index" :value="index - 1">
              {{ index - 1 }}
            </option>
          </select>
        </div>
      </div>
      <div class="mt-3 row align-items-center">
        <div class="col-auto">
          <label class="form-label mb-0">Allocated bags count</label>
        </div>
        <div class="col-auto">
          <select
            id="client-cutlery-count"
            v-model="clientAllocatedBagsCount"
            class="form-control"
          >
            <option v-for="index in 8" :key="index" :value="index - 1">
              {{ index - 1 }}
            </option>
          </select>
        </div>
      </div>
      <argon-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="updateDelivery"
        >Update
      </argon-button>
    </div>
  </div>
  <div v-if="!loading" id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Sessions</h5>
      <p class="text-sm">
        This is a list of devices that have logged into your account. Remove
        those that you do not recognize.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">Bucharest 68.133.163.201</p>
            <p class="mb-0 text-xs">Your current session</p>
          </div>
        </div>
        <argon-badge color="success" size="sm" class="my-auto ms-auto me-3"
          >Active
        </argon-badge>
        <p class="text-secondary text-sm my-auto me-3">EU</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Chrome on macOS</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-mobile text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Safari on iPhone</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
  <div v-show="!loading" id="account-status" class="card mt-4">
    <div class="card-header">
      <h5>Deactivate Account</h5>
      <p class="text-sm mb-0">You can deactivate account here.</p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <p class="text-sm text-secondary ms-auto me-3 my-auto">Active</p>
      <div class="my-auto">
        <argon-switch
          id="account-status-switch"
          v-model:checked="client_account_status"
          name="Active"
          @change="updateClientAccountStatus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import img from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/small-logos/logo-slack.svg";
import img2 from "@/assets/img/small-logos/logo-spotify.svg";
import img3 from "@/assets/img/small-logos/logo-atlassian.svg";
import img4 from "@/assets/img/small-logos/logo-asana.svg";
import profileImage from "@/assets/img/user-profile-icon.svg";
import API from "@/services/api";
import ApiClientDeliveryPreferences from "@/services/apiClientDeliveryPreferences";
import ApiAddresses from "@/services/apiAddresses";
import apiClientProfile from "@/services/apiClientProfile";
import apiRoles from "@/services/apiRoles";
import apiMiscList from "@/services/apiMiscList";
import LoadingSpinner from "@/components/LoadingSpinner";
import { useVuelidate } from "@vuelidate/core";
import { validatorMessages, required, email, helpers } from "@/lib/validators";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { showMessage } from "@/assets/js/show-message";
import AddressesCard from "@/views/pages/Clients/components/AddressesCard";
import { initChoices, setChoiceByValue } from "@/assets/js/init-choices";
import { handleError } from "@/lib/helpers";
import { timeFormat } from "@/lib/dateHelper";
import { createFormDataFromObject } from "@/lib/objectHelper";
import constants from "@/constants";
import { mapGetters } from "vuex";
export default {
  name: "EditSideNavItem",
  components: {
    ArgonButton,
    ArgonSwitch,
    ArgonBadge,
    ArgonAvatar,
    ArgonInput,
    ArgonTextarea,
    SubmitFormButton,
    ErrorDisplay,
    LoadingSpinner,
    flatPickr,
    AddressesCard,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      img,
      img1,
      img2,
      img3,
      img4,
      profileImage,
      client: {
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        gender: "",
        birth_date: "",
        profile_picture: "",
        notes: "",
        private_notes: "",
      },
      referralForm: {
        referral_code: "",
      },
      clientProfile: {
        weight: "",
        height: "",
        goal: "",
      },
      loading: true,
      spinner: {
        color: "#3AB982",
        size: "48px",
      },
      addressAreas: [],
      clientAddresses: [],
      clientSupportTeam: {
        account_manager_id: "",
        nutritionist_id: "",
        sales_owner_id: "",
      },
      client_account_status: false,
      clientDeliveryPreferences: [],
      clientDeliveryPreferencesAddressChoices: [],
      clientDeliveryPreferencesScheduleChoices: [],
      formSubmitted: {
        clientBasicInfo: false,
        clientSupportTeam: false,
        deliveryPreferences: false,
      },
      profilePicture: undefined,
    };
  },
  computed: {
    clientName() {
      return this.client.first_name + " " + this.client.last_name;
    },
    clientCutleryCount: {
      get() {
        return this.client.meta && this.client.meta.cutlery_count !== undefined
          ? this.client.meta.cutlery_count
          : 1;
      },
      set(value) {
        if (!this.client.meta) {
          this.client.meta = {};
        }
        this.client.meta.cutlery_count = value;
      },
    },
    clientAllocatedBagsCount: {
      get() {
        return this.client.meta && this.client.meta.allocated_bags_count
          ? this.client.meta.allocated_bags_count
          : 3;
      },
      set(value) {
        if (!this.client.meta) {
          this.client.meta = {};
        }
        this.client.meta.allocated_bags_count = value;
      },
    },
    ...mapGetters({
      hasRole: "auth/hasRole",
      getCurrentUserId: "auth/getCurrentUserId",
    }),
    isAccountManager() {
      return this.hasRole(constants.ROLES.ACCOUNT_MANAGER);
    },
  },
  watch: {},
  async mounted() {
    this.addressAreas = await ApiAddresses.getAreas("start=0&length=-1");
    await this.setClient();
    await this.initAccountManagerChoices();
    await this.initNutritionistChoices();
    await this.initSalesOwnerChoices();
    await this.setDeliveryPreferences();
    await this.initDeliveryPreferencesAddressChoices();
    await this.initGoalChoices();
    this.initGenderChoices();
    this.loading = false;
  },
  methods: {
    async initGoalChoices() {
      initChoices(
        "goal",
        { choices: apiMiscList.healthGoalChoices() },
        this.clientProfile.goal
      );
    },
    async updateDeliveryPreferences() {
      if (this.formSubmitted.deliveryPreferences) {
        showMessage("Saving data. Please wait", "", 1500);
      }
      this.formSubmitted.deliveryPreferences = true;
      let formData = new FormData();
      for (let i in this.clientDeliveryPreferences) {
        for (let key in this.clientDeliveryPreferences[i])
          formData.append(
            "client_delivery_preferences[" + i + "][" + key + "]",
            this.clientDeliveryPreferences[i][key]
          );
        formData.set(
          "client_delivery_preferences[" + i + "][delivery]",
          this.clientDeliveryPreferences[i]["delivery"] ? 1 : 0
        );
      }
      let saveResponse = { status: 0 };
      let message = "";
      saveResponse = await ApiClientDeliveryPreferences.store(
        this.$route.params.id,
        formData
      ).catch(API.handleError);
      let messageTimer = 2500;
      if (saveResponse.status === 200) {
        message =
          '<i class="fas fa-check-circle text-md ms-3 text-success"></i> <span>' +
          "Client delivery preferences saved successfully." +
          "</span>";
      } else {
        message =
          '<i class="fas fa-exclamation-triangle text-md ms-3 text-danger"></i> <span>' +
          saveResponse.message +
          "</span>";
        messageTimer = 3500;
      }
      showMessage(message, "", messageTimer, "", "html");
      this.formSubmitted.deliveryPreferences = false;
    },
    async updateDelivery() {
      this.updateDeliveryPreferences();

      const formData = new FormData();
      formData.append("meta[cutlery_count]", this.clientCutleryCount);
      formData.append(
        "meta[allocated_bags_count]",
        this.clientAllocatedBagsCount
      );
      await this.apiUpdateClient(formData);
    },
    setDeliveryPreferencesAddressChoices() {
      this.clientDeliveryPreferencesAddressChoices = [];
      for (let i in this.clientAddresses) {
        this.clientDeliveryPreferencesAddressChoices.push({
          id: this.clientAddresses[i].id,
          value: this.clientAddresses[i].id,
          //label: this.clientAddresses[i].type
          label:
            this.clientAddresses[i].flat_number +
            ", " +
            this.clientAddresses[i].building_name +
            ", " +
            this.clientAddresses[i].street +
            ", " +
            this.clientAddresses[i].landmark +
            ", " +
            this.clientAddresses[i]["area.name"],
        });
      }
    },
    async initDeliveryPreferencesAddressChoices(data) {
      if (data) {
        this.clientAddresses = data;
      }
      await this.setDeliveryPreferencesAddressChoices();
      const appInstance = this;
      for (let i in this.clientDeliveryPreferences) {
        let id = "delivery-address-" + this.clientDeliveryPreferences[i].day;
        initChoices(
          id,
          {
            choices: this.clientDeliveryPreferencesAddressChoices,
            shouldSort: false,
          },
          this.clientDeliveryPreferences[i].address_id
        );
        this.initClientDeliveryPreferencesScheduleChoices(
          this.clientDeliveryPreferences[i].day,
          this.getAddressAreaTimings(
            this.clientDeliveryPreferences[i].address_id
          ),
          this.clientDeliveryPreferences[i].area_timing_id
        );
        document
          .getElementById(id)
          .addEventListener("change", async (event) => {
            const areaTimings = appInstance.getAddressAreaTimings(
              event.detail.value
            );
            appInstance.clientDeliveryPreferences[i].area_timing_id = 0;
            appInstance.initClientDeliveryPreferencesScheduleChoices(
              appInstance.clientDeliveryPreferences[i].day,
              areaTimings
            );
          });
      }
    },
    initClientDeliveryPreferencesScheduleChoices(i, areaTimings, selected) {
      initChoices(
        "delivery-area-timing-" + i,
        {
          choices: areaTimings,
          shouldSort: false,
        },
        selected
      );
    },
    getAddressAreaTimings(address_id) {
      return this.getAreaTimings(this.getAddressAreaId(address_id));
    },
    getAddressAreaId(address_id) {
      const item = this.clientAddresses.find((el) => el.id == address_id);
      if (item) {
        return item.area_id;
      }
      return 0;
    },
    getAreaTimings(area_id) {
      const item = this.addressAreas.find((el) => el.id == area_id);
      let result = [];
      if (item) {
        for (let i in item.areaTimings.data) {
          result.push({
            id: item.areaTimings.data[i].id,
            value: item.areaTimings.data[i].id,
            label:
              item.areaTimings.data[i].delivery_schedule +
              " (" +
              timeFormat(item.areaTimings.data[i].start_time) +
              "-" +
              timeFormat(item.areaTimings.data[i].end_time) +
              ")",
          });
        }
      }
      return result;
    },
    setDefaultDeliveryPreferences() {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      return days.map((day, index) => ({
        id: 0,
        address_id: 0,
        day_text: day,
        day: index,
        delivery: 0,
        delivery_schedule: "Morning",
        area_timing_id: 0,
      }));
    },
    async setDeliveryPreferences() {
      const appInstance = this;
      await ApiClientDeliveryPreferences.index(appInstance.$route.params.id)
        .then((res) => {
          this.clientDeliveryPreferences = res.data.data;
          if (this.clientDeliveryPreferences.length === 0) {
            this.clientDeliveryPreferences =
              this.setDefaultDeliveryPreferences();
          }

          this.clientDeliveryPreferences.forEach((pref) => {
            pref.delivery = pref.delivery == 1;
          });
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },

    async initAccountManagerChoices() {
      await API.getAccountManagers()
        .then((res) => {
          const id = "client-am";
          initChoices(
            id,
            {
              choices: API.formatDataToChoicesJs(res.data.data),
              allowHTML: false,
            },
            this.clientSupportTeam.account_manager_id
          );
          if (!this.$ability.can("update_account_manager", "clients")) {
            document.getElementById(id)._choices.disable();
          }
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initNutritionistChoices() {
      await API.getNutritionists()
        .then((res) => {
          const id = "client-nutritionist";
          initChoices(
            id,
            {
              choices: API.formatDataToChoicesJs(res.data.data),
              allowHTML: false,
              searchEnabled: true,
              searchFields: ["label"],
              searchPlaceholderValue: "Type here to search",
              shouldSort: false,
            },
            this.clientSupportTeam.nutritionist_id
          );
          if (!this.$ability.can("update_nutritionist", "clients")) {
            document.getElementById(id)._choices.disable();
          }
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async initSalesOwnerChoices() {
      await apiRoles
        .salesOwners()
        .then((res) => {
          const id = "client-sales-owner";
          initChoices(
            id,
            {
              choices: API.formatDataToChoicesJs(res.data.data),
              allowHTML: false,
              searchEnabled: true,
              searchFields: ["label"],
              searchPlaceholderValue: "Type here to search",
              shouldSort: false,
            },
            this.clientSupportTeam.sales_owner_id
          );
          if (!this.$ability.can("update_sales_owner", "clients")) {
            document.getElementById(id)._choices.disable();
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setClient() {
      const appInstance = this;
      await API.getClient(appInstance.$route.params.id, true)
        .then((res) => {
          const { data } = res.data;
          const {
            id,
            first_name,
            last_name,
            email,
            mobile_number,
            gender,
            birth_date,
            profile_picture,
            meta,
            nutritionist_id,
            account_manager_id,
            sales_owner_id,
            notes,
            private_notes,
            addresses,
            status,
            profile,
            referral_code,
          } = data;

          this.client = {
            id,
            first_name,
            last_name,
            email,
            mobile_number,
            gender,
            birth_date,
            profile_picture: profile_picture.length
              ? profile_picture
              : this.profileImage,
            meta: meta || [],
            notes,
            private_notes,
          };
          this.referralForm = { referral_code: referral_code };
          this.clientProfile = {
            weight: profile.data.weight,
            height: profile.data.height,
            goal: profile.data.goal,
          };
          this.clientAddresses = addresses.data;

          this.clientSupportTeam = {
            nutritionist_id: nutritionist_id ?? "",
            account_manager_id: account_manager_id ?? "",
            sales_owner_id: sales_owner_id ?? "",
          };

          this.client_account_status = status === "Active";
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Client not found",
              willClose: () => {
                appInstance.$router.push({ name: "Clients List" });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        });
    },

    async updateClientBasicInfo() {
      await this.v$.$validate();
      if (
        this.v$.$validationGroups.client.$invalid ||
        this.formSubmitted.clientBasicInfo
      ) {
        return;
      }
      this.formSubmitted.clientBasicInfo = true;
      this.v$.$reset();
      const formData = createFormDataFromObject(this.client, [
        "profile_picture",
      ]);
      if (this.profilePicture) {
        formData.append("profile_picture", this.profilePicture);
      }
      await this.apiUpdateClient(formData);

      this.formSubmitted.clientBasicInfo = false;
    },
    async updateClientReferral() {
      if (this.formSubmitted.clientReferral) {
        return;
      }
      this.formSubmitted.clientReferral = true;
      this.v$.$reset();
      const formData = createFormDataFromObject({
        referral_code: this.referralForm.referral_code,
      });
      await this.apiUpdateClient(formData);

      this.formSubmitted.clientReferral = false;
    },
    async updateClientSupportTeam() {
      this.formSubmitted.clientSupportTeam = true;
      let formData = new FormData();
      if (
        this.$ability.can("update_account_manager", "clients") ||
        this.$ability.can("update_nutritionist", "clients") ||
        this.$ability.can("update_sales_owner", "clients")
      ) {
        if (this.$ability.can("update_account_manager", "clients")) {
          formData.append(
            "account_manager_id",
            this.clientSupportTeam.account_manager_id
          );
        }
        if (this.$ability.can("update_nutritionist", "clients")) {
          formData.append(
            "nutritionist_id",
            this.clientSupportTeam.nutritionist_id
          );
        }
        if (this.$ability.can("update_sales_owner", "clients")) {
          formData.append(
            "sales_owner_id",
            this.clientSupportTeam.sales_owner_id
          );
        }
        await this.apiUpdateClient(formData);
      }

      this.formSubmitted.clientSupportTeam = false;
    },
    async updateClientAccountStatus() {
      let formData = new FormData();
      formData.append(
        "status",
        this.client_account_status ? "Active" : "Inactive"
      );
      await this.apiUpdateClient(formData);
    },
    async apiUpdateClient(data) {
      if (this.loading) {
        return;
      }
      data.append("_method", "PATCH");
      API.updateClient(this.$route.params.id, data)
        .then(() => {
          showMessage("Client updated successfully", "success");
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async updateClientProfile() {
      if (this.loading) {
        return;
      }
      await this.v$.$validate();
      if (
        this.v$.$validationGroups.clientProfile.$invalid ||
        this.formSubmitted.clientProfile
      ) {
        return;
      }
      this.formSubmitted.clientProfile = true;
      const formData = createFormDataFromObject(this.clientProfile);
      apiClientProfile
        .update(this.client.id, formData)
        .then(() => {
          showMessage("Client profile updated successfully", "success");
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
      this.formSubmitted.clientProfile = false;
    },
    showProfilePicturePicker() {
      this.$refs.file.click();
    },
    selectProfilePicture() {
      this.profilePicture = this.$refs.file.files.item(0);
      this.client.profile_picture = URL.createObjectURL(this.profilePicture);
    },
    initGenderChoices() {
      initChoices(
        "gender",
        { allowHTML: true, shouldSort: false },
        this.client.gender
      );
    },
    async handleClientAddressChanged() {
      await this.setDeliveryPreferences();
      await this.initDeliveryPreferencesAddressChoices();
    },
    async addCurrentUserAsAccountManager() {
      this.formSubmitted.clientSupportTeam = true;
      let formData = new FormData();

      this.clientSupportTeam.account_manager_id = this.getCurrentUserId;
      setChoiceByValue("client-am", this.clientSupportTeam.account_manager_id);
      formData.append(
        "account_manager_id",
        this.clientSupportTeam.account_manager_id
      );
      await this.apiUpdateClient(formData);

      this.formSubmitted.clientSupportTeam = false;
    },
  },

  validations() {
    return {
      client: {
        first_name: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        last_name: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        email: {
          required: helpers.withMessage(validatorMessages.required, required),
          email: helpers.withMessage(validatorMessages.email, email),
        },
        mobile_number: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        gender: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
      clientProfile: {
        weight: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        height: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        goal: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
      clientAddresses: {
        $each: {
          area_id: {
            required: helpers.withMessage(validatorMessages.required, required),
          },
          city_id: {
            required: helpers.withMessage(validatorMessages.required, required),
          },
        },
      },
      $validationGroups: {
        client: ["client"],
        clientProfile: ["clientProfile"],
      },
    };
  },
};
</script>
