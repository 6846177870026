<template>
  <div class="container-fluid text-center p-0">
    <div class="d-inline-flex justify-content-center p-1 bg-light rounded-3">
      <button
        v-for="option in filterOptions"
        :key="option.value"
        class="btn py-2 text-center mb-0"
        :class="[
          interval === option.value ? 'btn-white' : 'shadow-none',
          { 'ms-2': option.value !== 'day' },
        ]"
        :disabled="loading"
        @click="updateStartDate(option.value)"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/lib/dateHelper";

export default {
  name: "HealthFilter",
  props: {
    interval: {
      type: [String, Number],
      default: "day",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["filterBy"],
  data() {
    return {
      startDate: "",
      filterOptions: [],
    };
  },
  mounted() {
    this.filterOptions = [
      { value: "day", label: "Day" },
      { value: -30, label: "30 days" },
      { value: -180, label: "6 months" },
      { value: -365, label: "1 year" },
    ];
  },
  methods: {
    updateStartDate(interval) {
      if (this.loading) return;

      this.startDate =
        interval === "day"
          ? dateFormat("today", "YYYY-MM-DD")
          : dateFormat(`${interval} days`, "YYYY-MM-DD");

      this.$emit(
        "filterBy",
        this.startDate,
        dateFormat("today", "YYYY-MM-DD"),
        interval
      );
    },
  },
};
</script>

<style scoped></style>
