import { httpApi } from "@/services/httpApi";

class apiMiscList {
  mealCodes() {
    const mealCodes = [];
    const mealTypes = this.mealTypes();
    mealTypes.forEach((mealType) => {
      for (let i = 1; i <= 4; i++) {
        mealCodes.push({
          name: `${mealType.name}${i}`,
          value: `${mealType.value} ${i}`,
        });
      }
    });
    return mealCodes;
  }

  mealTypes() {
    return [
      {
        name: "BF",
        value: "Breakfast",
      },
      {
        name: "MS",
        value: "AM Snack",
      },
      {
        name: "LU",
        value: "Lunch",
      },
      {
        name: "AS",
        value: "PM Snack",
      },
      {
        name: "DN",
        value: "Dinner",
      },
      {
        name: "AD",
        value: "Addon",
      },
    ];
  }
  async substituteMenuMealTypes() {
    return await httpApi.get("api/v1/misc/lists/meal-types");
  }
  async bagDeliveryStatus() {
    return await httpApi.get("api/v1/misc/lists/bag-delivery-status");
  }
  async clientDeliveryStatus() {
    return await httpApi.get("api/v1/misc/lists/client-delivery-status");
  }
  async bagMovementStatus() {
    return await httpApi.get("api/v1/misc/lists/bag-movement-status");
  }
  async bagCollectionRequestStatus() {
    return await httpApi.get("api/v1/misc/lists/bag-collection-request-status");
  }
  async discountCodeCategory() {
    return await httpApi.get("api/v1/misc/lists/discount-code-category");
  }
  async clientPlanStatus() {
    return await httpApi.get("api/v1/misc/lists/client-plan-status");
  }
  remarkTypes() {
    return [
      {
        name: "Sales",
        value: "Sales",
      },
      {
        name: "Operations",
        value: "Operations",
      },
      {
        name: "Nutrition",
        value: "Nutrition",
      },
      {
        name: "Packaging",
        value: "Packaging",
      },
    ];
  }
  servingInstructions() {
    return [
      {
        id: "Eat me cold",
        name: "Eat me cold",
      },
      {
        id: "Eat me cold / Reheat for 30 seconds",
        name: "Eat me cold / Reheat for 30 seconds",
      },
      {
        id: "Reheat for 30 seconds",
        name: "Reheat for 30 seconds",
      },
      {
        id: "Reheat for 60 seconds",
        name: "Reheat for 60 seconds",
      },
      {
        id: "Reheat for 90 seconds",
        name: "Reheat for 90 seconds",
      },
      {
        id: "Blend of shake with 200ml of ice cold water. Ice optional.",
        name: "Blend of shake with 200ml of ice cold water. Ice optional.",
      },
      {
        id: "Eat me cold / Reheat for 60 seconds",
        name: "Eat me cold / Reheat for 60 seconds",
      },
      {
        id: "Eat me cold / Reheat for 90 seconds",
        name: "Eat me cold / Reheat for 90 seconds",
      },
    ];
  }
  async manualPaymentSources() {
    return await httpApi.get("api/v1/misc/lists/manual-payment-source");
  }
  async invoiceRefundRequestType() {
    return await httpApi.get("api/v1/misc/lists/invoice-refund-request-type");
  }
  async invoiceRefundRequestStatus() {
    return await httpApi.get("api/v1/misc/lists/invoice-refund-request-status");
  }
  banks() {
    let options = [
      "Emirates NBD",
      "HSBC",
      "ADCB",
      "FAB",
      "CITIBANK",
      "ADIB (ABU DHABI ISLAMIC BANK)",
      "MASHREQ",
      "DIB (DUBAI ISLAMIC BANK)",
      "EMIRATES ISLAMIC BANK",
      "NATIONAL BANK OF RAS AL-KHAIMAH",
      "RAK BANK",
      "NBF (NATIONAL BANK OF FUJAIRAH)",
      "STANDARD CHARTERED BANK",
      "AL HILAL BANK",
      "OTHER BANK",
      "COMMERCIAL BANK OF DUBAI",
      "Al maryah community bank",
    ];
    let result = [];
    for (let i in options) {
      result.push({
        id: options[i],
        name: options[i],
      });
    }
    return result;
  }
  refundMediums() {
    let options = [
      "Bank deposit refund",
      "Driver handover refund (NOT ADVISABLE)",
      "Kca HQ Reception",
      "Wallet",
      "Online refund",
    ];
    let result = [];
    for (let i in options) {
      result.push({
        id: options[i],
        name: options[i],
      });
    }
    return result;
  }
  healthGoalChoices() {
    return [
      { value: "weight_loss", label: "Weight Loss" },
      { value: "weight_maintain", label: "Weight Maintain" },
      { value: "muscle_gain", label: "Muscle Gain" },
      { value: "improve_health", label: "Improve Health" },
      { value: "body_transformation", label: "Body Transformation" },
      { value: "post_natal_recovery", label: "Post Natal Recovery" },
    ];
  }
  validFor() {
    let options = ["Plan", "Addon"];
    let result = [];
    for (let i in options) {
      result.push({
        id: options[i],
        name: options[i],
      });
    }
    return result;
  }
  duration() {
    let options = ["1", "2", "3", "4", "5"];
    let result = [];
    for (let i in options) {
      result.push({
        id: options[i],
        name: options[i],
      });
    }
    return result;
  }
  validWeeks() {
    let options = ["1", "2", "4", "8", "12"];
    let result = [];
    for (let i in options) {
      result.push({
        id: options[i],
        name: options[i],
      });
    }
    return result;
  }
  validDaysPerWeek() {
    let options = ["5", "6", "7"];
    let result = [];
    for (let i in options) {
      result.push({
        id: options[i],
        name: options[i],
      });
    }
    return result;
  }
  async purchaseType() {
    return await httpApi.get("api/v1/misc/lists/purchase-type");
  }
  activityLevelChoices() {
    return [
      {
        value: "minimal",
        label: "Not active (0- 1 hrs per week)",
      },
      {
        value: "mild",
        label: "Lightly Active (1 - 3 hrs per week)",
      },
      {
        value: "moderate",
        label: "Moderate (4 - 5 hrs per week)",
      },
      {
        value: "heavy",
        label: "Very Active (6 - 8 hrs per week)",
      },
      {
        value: "extreme",
        label: "Extremely Active (9+ hrs per week)",
      },
    ];
  }
  bodyShapeChoices(gender) {
    const bodyShapeOptions = {
      Female: [
        {
          value: "rectangle",
          label: "Rectangle, Straight, or “Banana”",
        },
        {
          value: "inverted_triangle",
          label: "Inverted Triangle or “Apple”",
        },
        {
          value: "hourglass",
          label: "Hourglass",
        },
        {
          value: "triangle",
          label: "Triangle or “Pear”",
        },
        {
          value: "round",
          label: "Round or Oval",
        },
      ],
      Male: [
        {
          value: "rectangle",
          label: "Rectangle",
        },
        {
          value: "inverted_triangle",
          label: "Inverted Triangle",
        },
        {
          value: "trapezoid",
          label: "Trapezoid",
        },
        {
          value: "triangle",
          label: "Triangle",
        },
        {
          value: "round",
          label: "Round or Oval",
        },
      ],
    };
    return bodyShapeOptions[gender] || bodyShapeOptions["Male"];
  }
  getClientDislikeExclusionTypeChoices() {
    return [
      {
        value: "food_group",
        label: "Food Group",
        id: "",
      },
      {
        value: "ingredient",
        label: "Ingredient",
        id: "",
      },
      {
        value: "recipe",
        label: "Recipe",
        id: "",
      },
    ];
  }
  getDiscountCodeStatus(empty_option_name) {
    let options = [
      {
        value: "active",
        label: "Active",
        id: "",
      },
      {
        value: "inactive",
        label: "Inactive",
        id: "",
      },
    ];
    if (empty_option_name) {
      options = [
        {
          value: "",
          label: empty_option_name,
          id: "",
        },
        ...options,
      ];
    }
    return options;
  }
  async planAmendmentQueueStatus() {
    return await httpApi.get("api/v1/misc/lists/plan-amendment-queue-status");
  }
  async activityEventList() {
    return await httpApi.get("api/v1/misc/lists/activity-event-list");
  }
  async menuStatus() {
    return await httpApi.get("api/v1/misc/lists/menu-status");
  }
}

export default new apiMiscList();
