<template>
  <GradientLineChart
    v-if="chartData"
    id="blood-pressure"
    :key="chartData.labels.join(',')"
    :legend="true"
    :y-axis="true"
    :chart="chartData"
    class="h-100"
    :loading="loading"
  >
    <HealthHeader
      :selected-date="startDate"
      title="Blood Pressure"
      @update-graph="updateGraph"
    />
    <HealthFilter
      :loading="loading"
      :interval="interval"
      @filter-by="updateGraph"
    />
    <HealthNavigator
      :start-date="startDate"
      :end-date="endDate"
      :today="today"
      :interval="interval"
      :loading="loading"
      @update-graph="updateGraph"
    />
    <template #displayData
      ><ResultDisplay v-if="avg_systolic" title="Average Blood Pressure">
        <span>{{ avg_systolic }}/{{ avg_diastolic }} mmHg </span>
      </ResultDisplay>
    </template>
  </GradientLineChart>
</template>
<script>
import GradientLineChart from "@/components/GradientLineChart.vue";
import apiHealth from "@/services/apiHealth";
import HealthFilter from "./components/Filter.vue";
import ResultDisplay from "./components/ResultDisplay.vue";
import { dateFormat } from "@/lib/dateHelper";
import HealthNavigator from "./components/HealthNavigator.vue";
import HealthHeader from "./components/HealthHeader.vue";

export default {
  name: "BloodPressure",
  components: {
    GradientLineChart,
    HealthFilter,
    ResultDisplay,
    HealthNavigator,
    HealthHeader,
  },
  data() {
    return {
      interval: "day",
      chartData: "",
      avg_systolic: "",
      avg_diastolic: "",
      today: dateFormat("today", "YYYY-MM-DD"),
      startDate: "",
      endDate: "",
      loading: false,
    };
  },
  async mounted() {
    this.fetchBP(this.today, this.today);
  },
  methods: {
    dateFormat,
    async updateGraph(start, end, interval) {
      this.interval = interval;
      await this.fetchBP(start, end);
    },
    async fetchBP(start, end) {
      this.startDate = start;
      this.endDate = end;
      this.loading = true;
      try {
        const res = await apiHealth.getBloodPressure(
          this.$route.params.id,
          start,
          end
        );
        if (res && res.data.graphData) {
          const labels = Object.keys(res.data.graphData);
          const displayLabels = labels.map((label) =>
            /^\d{4}-\d{2}$/.test(label)
              ? dateFormat(new Date(label + "-01"), { month: "short" })
              : label
          );
          const systolicData = labels.map(
            (time) => res.data.graphData[time].systolic
          );
          const diastolicData = labels.map(
            (time) => res.data.graphData[time].diastolic
          );

          this.chartData = {
            labels: displayLabels,
            datasets: [
              {
                label: "Systolic Pressure",
                data: systolicData,
                borderColor: "#FF5733",
                backgroundColor: "rgba(255,150,130,0.2)",
              },
              {
                label: "Diastolic Pressure",
                data: diastolicData,
                borderColor: "#f0eb5b",
                backgroundColor: "rgba(250,235,150,0.2)",
              },
            ],
          };
          this.avg_systolic = res.data.average_systolic;
          this.avg_diastolic = res.data.average_diastolic;
        }
      } catch (error) {
        console.error("Error fetching blood pressure data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
