<template>
  <div class="card z-index-2">
    <div class="pb-0 card-header mb-0">
      <h6 v-if="title">{{ title }}</h6>
      <p v-if="description" class="text-sm" v-html="description" />
      <slot />
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
    <slot name="displayData" />
    <LoadingSpinner
      :loading="loading"
      size="16px"
      class="position-absolute top-50 start-50 translate-middle"
    />
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import LoadingSpinner from "./LoadingSpinner.vue";
export default {
  name: "GradientLineChart",
  components: {
    LoadingSpinner,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "300",
    },
    title: {
      type: String,
      default: "",
    },
    legend: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    yAxis: {
      type: Boolean,
      default: false,
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    chart: {
      deep: true,
      handler() {
        this.initChart();
      },
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const ctx = document.getElementById(this.id).getContext("2d");

      const defaultColors = [
        {
          border: "#4BB543",
          gradient: ["rgba(144,232,144,0.2)", "rgba(144,232,144,0)"],
        },
        {
          border: "#3A416F",
          gradient: ["rgba(155,160,200,0.2)", "rgba(155,160,200,0)"],
        },
        {
          border: "#FF5733",
          gradient: ["rgba(255,160,140,0.2)", "rgba(255,160,140,0)"],
        },
        {
          border: "#3498DB",
          gradient: ["rgba(140,200,250,0.2)", "rgba(140,200,250,0)"],
        },
      ];

      const datasets = this.chart.datasets.map((dataset, index) => {
        let colorIndex = index % defaultColors.length;
        let gradient = ctx.createLinearGradient(0, 230, 0, 50);
        gradient.addColorStop(
          1,
          dataset.backgroundColor || defaultColors[colorIndex].gradient[0]
        );
        gradient.addColorStop(
          0,
          dataset.backgroundColor || defaultColors[colorIndex].gradient[1]
        );

        return {
          label: dataset.label,
          tension: 0.4,
          borderWidth: 3,
          pointRadius: 0,
          borderColor: dataset.borderColor || defaultColors[colorIndex].border,
          backgroundColor: gradient,
          fill: true,
          data: dataset.data,
          maxBarThickness: 6,
        };
      });

      let chartStatus = Chart.getChart(this.id);
      if (chartStatus !== undefined) {
        chartStatus.destroy();
      }

      new Chart(ctx, {
        type: "line",
        data: {
          labels: this.chart.labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: this.legend,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              min: 0,
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: this.yAxis,
                padding: 10,
                color: "#ccc",
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#ccc",
                padding: 20,
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>
