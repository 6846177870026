<template>
  <div class="form-group flatpickr">
    <label v-if="label" class="form-label" :for="id">{{ label }}</label>
    <div class="input-group position-relative">
      <flat-pickr
        :id="id"
        :type="type"
        class="form-control"
        :class="getClasses"
        :name="name"
        :placeholder="placeholder"
        :model-value="modelValue"
        :config="flatPickrConfig"
        @input="(event) => $emit('update:model-value', event.target.value)"
        @blur="$emit('blur')"
      />
      <div class="input-group-append">
        <span v-if="selectedDateText" class="fs-6 me-1">{{
          selectedDateText
        }}</span>
        <button
          class="btn shadow-none mb-0 p-1"
          type="button"
          title="Toggle"
          data-toggle
        >
          <i class="fa fa-calendar fs-5" />
          <span aria-hidden="true" class="sr-only">Toggle</span>
        </button>
      </div>
    </div>
    <error-display :errors="errors" />
  </div>
</template>

<script>
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import flatPickr from "vue-flatpickr-component";
export default {
  name: "ArgonDate",
  components: { ErrorDisplay, flatPickr },
  props: {
    label: {
      type: String,
      default: "",
    },
    inputClasses: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [Number, String],
      default: "",
    },
    errors: {
      type: Object,
      default: () => {},
    },
    config: {
      type: Object,
      default: () => {},
    },
    showIconOnly: {
      type: Boolean,
      default: false,
    },
    selectedDateText: {
      type: String,
      default: "",
    },
  },
  emits: ["blur", "update:model-value"],
  data() {
    return {
      flatPickrConfig: {
        wrap: true,
        locale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },
  computed: {
    getClasses() {
      let sizeValue = this.size ? `form-control-${this.size}` : null;
      let iconOnlyClasses = this.showIconOnly
        ? `opacity-0 h-25 w-25 p-0`
        : null;
      return `${sizeValue} ${this.inputClasses} ${iconOnlyClasses}`;
    },
  },
  watch: {
    config: {
      deep: true,
      handler(newConfig) {
        this.flatPickrConfig = { ...this.flatPickrConfig, ...newConfig };
      },
    },
  },
  mounted() {
    this.flatPickrConfig = { ...this.flatPickrConfig, ...this.config };
  },
  methods: {},
};
</script>
