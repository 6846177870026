<template>
  <bar-chart
    v-if="chartData"
    id="blood-oxygen"
    :key="chartData.labels.join(',')"
    :legend="true"
    :chart="chartData"
    class="h-100"
    :loading="loading"
  >
    <HealthHeader
      :selected-date="startDate"
      title="Blood Oxygen"
      @update-graph="updateGraph"
    />
    <HealthFilter
      :interval="interval"
      :loading="loading"
      @filter-by="updateGraph"
    />

    <HealthNavigator
      :start-date="startDate"
      :end-date="endDate"
      :today="today"
      :interval="interval"
      :loading="loading"
      @update-graph="updateGraph"
    />

    <template v-if="avg_oxygen_sat" #displayData>
      <ResultDisplay title="Average Oxygen Saturation">
        <span class="">{{ avg_oxygen_sat }}</span>
      </ResultDisplay>
    </template>
  </bar-chart>
</template>
<script>
import BarChart from "../components/BarChart.vue";
import apiHealth from "@/services/apiHealth";
import HealthFilter from "./components/Filter.vue";
import ResultDisplay from "./components/ResultDisplay.vue";
import { dateFormat } from "@/lib/dateHelper";
import HealthNavigator from "./components/HealthNavigator.vue";
import HealthHeader from "./components/HealthHeader.vue";
export default {
  name: "BloodOxygen",
  components: {
    BarChart,
    HealthFilter,
    ResultDisplay,
    HealthNavigator,
    HealthHeader,
  },
  data() {
    return {
      interval: "day",
      chartData: null,
      avg_oxygen_sat: "",
      today: dateFormat("today", "YYYY-MM-DD"),
      startDate: "",
      endDate: "",
      loading: false,
    };
  },
  async mounted() {
    this.fetchBP(this.today, this.today, "day");
  },
  methods: {
    dateFormat,
    async updateGraph(start, end, interval) {
      this.interval = interval;
      await this.fetchBP(start, end);
    },
    async fetchBP(start, end) {
      this.loading = true;
      this.startDate = start;
      this.endDate = end;
      try {
        const res = await apiHealth.getOxygenLevel(
          this.$route.params.id,
          start,
          end
        );
        if (res && res.data.graphData) {
          const labels = Object.keys(res.data.graphData);
          const displayLabels = labels.map((label) =>
            /^\d{4}-\d{2}$/.test(label)
              ? dateFormat(new Date(label + "-01"), { month: "short" })
              : label
          );
          const oxygenData = labels.map((time) => res.data.graphData[time]);

          this.chartData = {
            labels: displayLabels,
            datasets: {
              label: "Oxygen Level",
              data: oxygenData,
              backgroundColor: "#fc2154",
            },
          };
          if (res.data.average_oxygen_saturation !== null) {
            this.avg_oxygen_sat = res.data.average_oxygen_saturation.toFixed(2);
          } else {
            this.avg_oxygen_sat = 0;
          }
        }
      } catch (error) {
        console.error("Error fetching body temperature data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
